/* divya */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --main-color: #12b8ff;
  --red: #e74c3c;
  --orange: #f39c12;
  --light-color: #888;
  --light-bg: #eee;
  --black: #2c3e50;
  --even: #000000;
  --leader: #fff;
  --github: #24292f;
  --white: #fff;
  --border: 0.1rem solid rgba(0, 0, 0, 0.2);
  /* --sidebar-margin-closed: 11.5rem; */
  --sidebar-transition-speed: 0.3s;
  --body-color: #e7e7e7;
  --sidebar-color: #fff;
  --primary-color: #ff4500;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
  /* --sidebar-width: 20rem; */
  --event-theme:rgb(0 0 255 / 55%);
 --commentShadow:0 0 1rem rgb(0 0 0 / 27%);
  --bgTransperent:#5badad12;
  --registerHeader:#12b8ff;
}
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  font-family: 'Jost', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none !important;
}

*::selection {
  background-color: var(--main-color);
  color: #fff;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 1rem;
  height: 0.5rem;

}

html::-webkit-scrollbar-track {
  background-color: transparent;

}

html::-webkit-scrollbar-thumb {
  border-radius: 5rem;
  background-color: var(--main-color);
}
body {
  /* background-image: url("C:/Users/Galaxy/Desktop/sar/client/src/components/images/bg.jpg"); */
  overflow-x: hidden;
  background-color: var(--white);
  padding-left: 0rem;
  background-size: 100%;
  /* min-height: 100vh; */
  background-color: var(--body-color);
  transition: var(--tran-05);
  /* margin-left: 8.8rem; */
  --black: #000;
  --main-color: #5badad;
  --primary-color: #5badad;
  transition: var(--tran-05);
  transition: margin-left var(--sidebar-transition-speed);
  font-family: 'San Francisco', Fallback, sans-serif;
  /* --logo: url("./components/images/Asset\ light.svg"); */
  --headerFilter: blur(2rem);
  --headerBgcolor: rgba(255, 255, 255, 0.1);
  --headerShadow: 1rem 1rem 20rem 1rem rgba(169, 166, 166, 0.397);
  --sidebarFilter: blur(2rem);
  --sidebarBgcolor: rgba(255, 255, 255, 0.1);
  --eventSideFilter: blur(3.2rem);
    --eventSideBgcolor: rgb(237 234 238 / 0%);
    --eventSideShadow: 0 2rem 5rem rgb(174 218 223);
  --eventSideTabFilter: blur(1.2rem);
  --eventSideTabBgcolor: rgba(49, 182, 192, 0.301);
  --eventSideTabShadow:0 1rem 5rem rgba(223, 220, 220, 0.5);
--bgImage: url("../public/images/lightbg-min.gif");
--bgImageFilter: blur(3rem) brightness(1.8);
--quickBoxShadow: 9rem 5rem 66rem 3rem rgb(190 190 190 / 63%);
--quickBoxFilter:  blur(3rem) brightness(0.95);
--homeGBFa:3.1rem 0.1rem 9rem rgb(180 180 180 / 58%);
--homeGBFRoadShadow: 0.1rem -2.9rem 3rem -3rem rgba(199 199 199 / 76%);
--homeGBFaRoadFilter: blur(20rem);
--backgroundHBT1: #BDBDBD;
--backgroundHBT: repeating-radial-gradient(circle farthest-corner at center center, #BDBDBD 0%, #000000 100%);
--background-clip-HBT: text;
--text-fill-color-HBT: transparent;
--b56BgColor: #0dcaf04a;
--b56Border: 0.2rem solid rgb(11 9 12);
--reviewBgColor:blur(2rem);
--reviewBShadow:0 0 2rem rgba(102, 103, 103, 0.7);
--gitaColor: #734651;
--line:linear-gradient(to right, transparent 50%, rgb(58 255 229 / 56%) 50%);
--sidebarHoverFilter: blur(-3rem);
--sidebarHoverShadow: 0 0rem 5rem -1rem #000000;
--subscribeInputShadow:inset 0 0 1rem 0.2rem rgb(160 160 160 / 76%);
--reviewHoverShadow:inset 1rem 1rem 4rem #a8adb263,
3rem 3rem 3rem -1rem rgb(24 23 23 / 21%),
-5rem -5rem 11rem -3rem rgba(13, 216, 213, 0.288);
--roadmap:rgb(252, 248, 248);
--roadmap-slide:linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
--roadmap-text:black;
--roadmap-box-shadow:0rem 0rem 0rem rgba(117, 117, 117, 0.694);
--btn:#5badad;
--btnHover:#2c3e50;
--btnColor:#0a1d4d;
--whyUs:rgb(0 0 0 / 52%);
--semActive:#5badad;
--pBBHover:rgb(45 197 192);
--dropDownColor:#2c3e50;
--registerTypeingColor:#b78161;
--eventPostFilter:blur(1.2rem);
--eventPostShadow:3rem 3rem 3rem -1rem rgb(144 142 142 / 71%);
--eventPostHoverShadow:0 4rem 6rem rgba(0, 0, 0, 0.3);
--eventPostBgColor:linear-gradient(to top, #000000 0%, #0ccfcf47 100%);
--roadshapBG1:#ffffff;
--roadshapBS1:inset 25px 25px 50px #f8f5f5d7,inset -2.5rem -2.5rem 5rem #acababc8;
--roadshapBG2:#f5f4f4d2;
--roadshapBS2:inset -25px -25px 50px #eeeeee, inset 25px 25px 50px #efeeeec9;
--roadshapBG3:#e6e0e0;
--roadshapBS3:inset -25px -25px 50px #ffffff, inset 25px 25px 50px #d2d1d1;
--main-title:radial-gradient(circle farthest-side at center center, #000c0c59 0%, #003eff 100%);
--aboutCardBG:rgba(255, 255, 255, 1);
--aboutcardbottomText:#2b2c48;
--popup:#e4fffc;
--dragBG:#1bd6d6;
--dropBorder:rgb(27 214 214);
--dropWave: rgb(27 214 214);
--roadmapText:#ce8c0f;
--roadmapTitle:#6868ab;
scroll-behavior: smooth;
}

body.dark {
  /* background-color: var(--light-bg); */
  /* background-image: linear-gradient(
    109.6deg,
    rgba(118, 12, 99, 1) 11.2%,
    rgba(71, 11, 75, 1) 98.6%
  ); */

  /* background-image: url(../src/components/images/Love\ and\ Liberty.jpg); */
  /* background-size: 200%; */
  /* backdrop-filter: blur(12px); */
  --main-color: rgb(10 29 77);
  --light-color: #aaa;
  --light-bg: #5e5e6118;
  --leader: #222;
  --even: #ffffff;
  --github: #fff;
  --black: #fff;
  --white: #3434340a;
  --border: 0.1rem solid rgba(255, 255, 255, 0.2);
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #1a0dab;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
  /* --logo: url("./components/images/Asset\ dark.svg"); */
  --headerFilter: blur(1.2rem);
  --headerBgcolor:  rgba(0, 0, 0, 0.56);
  --headerShadow:35rem -3rem 36rem 5rem rgb(0 0 0);
  --sidebarFilter: blur(1.2rem);
  --sidebarBgcolor: rgba(0, 0, 0, 0.56);
  --eventSideFilter: blur(2.2rem);
  --eventSideBgcolor: 1222394d;
  --eventSideShadow: 0 1rem 5rem rgba(0, 0, 0, 0.4);
  --eventSideTabFilter: blur(1.2rem);
  --eventSideTabBgcolor: rgb(10 29 77);
  --eventSideTabShadow: 0 0rem 17rem 5rem rgb(25 21 100 / 74%);
  --bgImage: url("../public/images/window-dark-min.jpg");
  --bgImageFilter:blur(10.5rem) brightness(0.4);
  --quickBoxShadow: 0 0 2rem rgba(0, 0, 0, 0.7);
  --quickBoxFilter: blur(2rem);
  --homeGBFa:   0.1rem 0.1rem 10rem  rgba(0, 0, 0, 0.726);
  --homeGBFRoadShadow: 0.1rem -2.9rem 3rem -3rem rgba(0, 0, 0, 0.726);
  --homeGBFaRoadFilter: blur(20rem);
  --backgroundHBT: repeating-radial-gradient(circle farthest-corner at center center, #BDBDBD 0%, #000000 100%);
  --background-clip-HBT: text;
  --text-fill-color-HBT: transparent;
  --b56BgColor: rgba(39, 9, 144, 0.2);
  --b56Border:0.2rem solid rgba(157, 154, 159, 0.4);
--reviewBgColor:blur(2rem);
--reviewBShadow:0 0 2rem rgba(0, 0, 0, 0.7);
--reviewHoverShadow:inset 2.5rem 0.3rem 7.5rem #0d6efd63,
                    -5rem -5rem 5rem -5rem #000000e6,
                    .1rem .1rem 58rem -7rem rgba(255, 255, 255, 0.489);
                    --roadmap:black;
                    --roadmap-slide:linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
                    --roadmap-text:white;
                    --roadmap-box-shadow:inset 0rem 0rem 0rem 0rem rgb(2 173 229);
--gitaColor:white;
--line:linear-gradient(to right, transparent 50%, #f9f9f9 50%);
--sidebarHoverFilter: blur(0rem);
--sidebarHoverShadow: 0 0rem 4rem -1rem #ffffff;
--subscribeInputShadow:inset 0px 0px 10px rgba(101, 101, 102, 0.25);
--btn:rgb(10 29 77);
--btnHover:#3733dc63;
--btnColor:#0c1d4600;
--whyUs:rgb(189 207 233 / 63%);
--semActive:rgb(37 58 111);
--pBBHover:rgb(135 159 219 / 53%);
--dropDownColor:#6aa9ff;
--registerTypeingColor:#9fbbef;
--eventPostShadow: 3rem 3rem 3rem -1rem rgb(12 22 62 / 63%);
--eventPostHoverShadow: 0 4rem 6rem rgb(0 0 0);
--eventPostBgColor:linear-gradient(to top, #000000 0%, #0c14cf47 100%);
--roadshapBG1:#071129;
--roadshapBS1:inset -25px -25px 50px #152758, inset 25px 25px 50px #000000;
--roadshapBG2:#071129;
--roadshapBS2:inset -25px -25px 50px #152758, inset 25px 25px 50px #000000;
--roadshapBG3:#071129;
--roadshapBS3:inset -25px -25px 50px #152758, inset 25px 25px 50px #000000;
--main-title:radial-gradient(circle farthest-side at center center, #ffffff 0%, #00ff0d 100%);
--aboutCardBG:#060b1600;
--aboutcardbottomText:#ffffff;
--popup:#06102b;
--dragBG:rgba(255, 255, 255, 0.1);
--dropBorder:rgba(255, 255, 255, 0.2);
--dropWave: rgba(255, 255, 255, 0.2);
--roadmapText:wheat;
--roadmapTitle:aqua;
scroll-behavior: smooth;

}

body.active {
  padding-left: 0;
}
/* Header css starts here  */
/* header{
  position: sticky !important;
} */
.header button{
  background: transparent;
}
/* CSS for the button with class 'buttongpt' */
.buttongpt {
  background: transparent;
  border: none;
  position: relative;
  /* color: #00d5ff; */
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
}

/* CSS for the initial bottom border */
.buttongpt::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  border-bottom: 0.4rem solid #5badad;
  transition: height 0.3s;
}

/* CSS for the button on hover */
.buttongpt:hover::before {
  height: 100%;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  border-left: 2px solid #5badad;
  border-right: 2px solid #5badad;
  border-top: 2px solid #5badad;
  border-bottom: 2px solid #5badad;
}
@keyframes move {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(calc(100vw - 100%), calc(100vh - 100%));
  }
}

.icon {
  position: absolute;
  animation: move linear infinite;
  animation-duration: 5s;
  animation-direction: alternate;
}

@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");
/* collegpt and searchbar css starts here  */
.collegegpt {
  font-family: "Cinzel", serif;
  font-size: 9rem;
  text-align: center;
  color: var(--black);
  text-shadow: 0rem 0rem 1rem rgba(255, 255, 255, 0.7);
}
.collegegpt-content {
  font-family: "Avantgarde", serif;
  font-size: 3.5rem;
  text-align: center;
  color: var(--light-color);
  text-shadow: 0rem 0rem 0.7rem yellow;
}

.userlist-searchbar {
  width: 50rem;
  border-radius: 0.5rem;
  background-color: rgb(92 173 173 / 25%);
  padding: 0.5rem 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0 auto;
}
.userlist-searchbar input {
  width: 100%;
  font-size: 1.8rem;
  color: var(--black);
  background: none;
}

.userlist-searchbar button {
  background: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--black);
}

.userlist-searchbar button:hover {
  color: var(--main-color);
}

section {
  padding: 0rem 2rem 2rem 2rem;
  margin: 0 auto;
  max-width: 125rem;
}

.heading {
  font-size: 2.5rem;
  color: var(--black);
  margin-bottom: 2.5rem;
  border-bottom: var(--border);
  padding-bottom: 1.5rem;
  text-transform: capitalize;
}

.inline-btn,
.inline-option-btn,
.inline-delete-btn,
.btn,
.delete-btn,
.option-btn {
  border-radius: 5rem;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  text-transform: capitalize;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 1rem;
}
.btn {
  display: block;
  width: 100%;
}
.delete-btn,
.option-btn {
  display: block;
  width: 100%;
}

.inline-btn,
.inline-option-btn,
.inline-delete-btn {
  display: inline-block;
}

.btn,
.inline-btn {
  background-color: var(--btn);
  margin-top: auto;
  margin-bottom: 2rem;
}

.option-btn,
.inline-option-btn {
  background-color: var(--orange);
}

.delete-btn,
.inline-delete-btn {
  background-color: var(--red);
}

.inline-btn:hover,
.inline-option-btn:hover,
.inline-delete-btn:hover,
.btn:hover,
.delete-btn:hover,
.option-btn:hover {
  background-color: var(--btnHover);
  background: var(--btnColor);
  color: white;
}

.flex-btn {
  display: flex;
  gap: 1rem;
}
/* welcome message  */

@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Inconsolata");

@import url("https://fonts.googleapis.com/css2?family=Cinzel&family=Handjet:wght@200&family=Satisfy&display=swap");
.welcome-container {
  height: 30vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 0;
  margin-top: 6rem !important;
  width: 100rem;
  flex-wrap: nowrap;
  align-content: center;
  margin: 0 auto; /* Updated property to center the container */
  margin-left: 0rem;
  width: 100%;
}
.welcome-container p {
  font-size: 4.5rem;
  padding: 0.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;
  color: wheat;
}
.welcome-container h2 {
  font-family: "Cinzel", serif;
  font-size: 4.5rem;
  font-weight: bold;
  padding: 0.5rem;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;
  color: var(--registerHeader);
}
.welcome-container p span.welcome-typed-text {
  font-weight: normal;
  color: var(--registerTypeingColor);
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-align: center;
  overflow: hidden;
  font-family: "Handjet", cursive;
  font-size: 4.5rem;
}
.welcome-container p span.welcome-cursor {
  display: inline-block;
  background-color: #ccc;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}
.welcome-container p span.welcome-cursor.welcome-typing {
  animation: none;
}
@keyframes blink {
  0% {
    background-color: #ccc;
  }
  49% {
    background-color: #ccc;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ccc;
  }
}

.welcome-svg-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 320px;
}
.welcome-shape {
  stroke-dasharray: 209 540;
  stroke-dashoffset: -438;
  stroke-width: 8px;
  fill: transparent;
  stroke: #5badad;
  /* border-bottom: 5px solid black; */
  transition: stroke-width 1s, stroke-dashoffset 1s, stroke-dasharray 1s;
}
.welcome-text {
  font-family: "Jost", sans-serif;
  font-size: 3.25rem;
  line-height: 32px;
  letter-spacing: 8px;
  fill: var(--black);
  transition: fill 1s;
  color: var(--black);
}
.welcome-gpt{
  /* color: var(--logo); */
  color:#5badad;
}
.welcome-svg-wrapper:hover .welcome-shape {
  stroke-width: 2px;
  stroke-dashoffset: 0;
  stroke-dasharray: 760;
}

tspan {
  fill: #5badad;
  font-family: "Bradley Hand ITC", cursive;
  font-weight: bolder;
}

.welcome-center-container {
  /* background: rgba(20, 20, 20, 0.103); */
  text-align: center;
  width: auto;
  border-radius: 0.5rem;
  justify-content: center;
  padding: 0.5rem 0rem;
  display: flex;
  gap: 2rem;
  height: 7rem;
  margin-top: -1rem;
}
[class*="logo-"] {
  display: flex;
  justify-content: center;

}
[class*="logo-"]:before {
  content: '';
  display: flex;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-background-size: contain;

}
.logo-nameHeader {
  font-size: 20rem;
margin-top: 0.5rem;
  background-repeat: no-repeat;
  background-image: var(--logo);
}
.flex .icons{
  /* margin-left: -2rem; */
  display: flex;
}
.header .flex .logo-trans {
  font-size: 2.5rem;
  color: transparent;
  margin-left: -16rem;
  margin-right: 2rem;
}
/* Header css starts here  */
.header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: var(--headerFilter);
  background-color: var(--headerBgcolor);
  box-shadow: var(--headerShadow);
  z-index: 10000;
  height: 8rem;
  padding-top: 0.5rem;
}


.header .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1rem 2rem;
}

.header .flex .logo {
  font-size: 2.5rem;
  color: var(--black);
}
.header .flex .logo:hover {
  color: var(--main-color);
}

.header .flex .search-form {
  width: 50rem;
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 0.5rem 2rem;
  display: flex;
  gap: 2rem;
}

.header .flex .search-form input {
  width: 100%;
  font-size: 1.8rem;
  color: var(--black);
  background: none;
}

.header .flex .search-form button {
  background: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--black);
}

.header .flex .search-form button:hover {
  color: var(--main-color);
}

.header .flex .icons div {
  font-size: 2rem;
  color: var(--black);
  /* background-color: var(--light-bg); */
  border-radius: 2rem;
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  cursor: pointer;
  text-align: center;
  /* margin-left: 0.7rem; */
}
body.dark 
.header .flex .icons div:hover {
  /* background-color: var(--black); */
  color: white;
}
 
.header .flex .icons div:hover {
  background-color: #4344471c;
  -webkit-backdrop-filter: var(--sidebarHoverFilter);
  backdrop-filter: var(--sidebarHoverFilter);
  box-shadow: var(--sidebarHoverShadow);
  /* color: var(--primary-color); */
  transform: translate(0rem, -0.7rem);
  transition: 0.5s ease;
}

.header .flex .profile {
  position: absolute;
  top: 120%;
  right: 2rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;
  overflow: hidden;
  transform-origin: top right;
  transform: scale(0);
  transition: 0.2s linear;
  width: 30rem;
  background: var(--popup);
    -webkit-backdrop-filter: var(--quickBoxFilter);
    backdrop-filter: var(--quickBoxFilter);
    box-shadow: var(--quickBoxShadow);
}

.header .flex .profile.active {
  transform: scale(1);
}

.header .flex .profile .image {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 1rem;
  margin-left: 9rem;
}

.header .flex .profile .name {
  font-size: 2rem;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header .flex .profile .role {
  font-size: 1.8rem;
  color: var(--light-color);
}

#search-btn {
  display: none;
}

/* Header css ends here  */

/* Sidebar css starts here*/
::selection {
  background-color: var(--primary-color);
  color: #fff;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 8rem;
  height: 100%;
  width: 22rem;
  padding: 1rem 0.7rem;
  backdrop-filter: var(--sidebarFilter);
  background-color: var(--sidebarBgcolor);
  /* box-shadow: 1rem 1rem 12rem 1rem rgba(169, 166, 166, 0.397); */
  transition: var(--tran-05);
  z-index: 100;
}
.navbar{
  height: 100%;
  backdrop-filter: var(--sidebarFilter);
  background-color: var(--sidebarBgcolor);
}
.sidebar.close {
  width: 10rem;
}
.sidebar.open {
  width: var(--sidebar-width);
}

.sidebar ul {
  display: grid;
  gap: 4rem;
}

.sidebar .name {
  font-size: 2rem;
  font-weight: bolder;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;
  color: var(--black);
}
.sidebar li {
  height: 5rem;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.sidebar .text,
.sidebar .icon {
  color: var(--black);
  transition: var(--tran-03);
}
.sidebar .text {
  font-size: 1.7rem;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
  margin-left: 2rem;
}
.sidebar.close .text {
  opacity: 0;
}
.sidebar header {
  position: relative;
}
.sidebar.close ul {
  flex-direction: row;
  align-items: center;
  gap: 2rem; /* Gap when the sidebar is closed */
  margin-left: 2rem;
}
.sidebar.open ul {
  flex-direction: column;
  align-items: center;
  gap: 0.4rem; /* Gap when the sidebar is open */
  margin-left: 1.5rem;
}
.sidebar header .logo-text {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}
.sidebar header .image-text .name {
  margin-top: 0.5rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.sidebar header .image-text .profession {
  font-size: 1.6rem;
  margin-top: -0.5rem;
  display: block;
}
.sidebar header .image {
  width: 7rem;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-left: 1rem;
}
.sidebar.open header .image {
  width: 14rem;
  height: 14rem;
}
.sidebar header .image img {
  border-radius: 50%;
}
.sidebar .fa-home {
  margin-left: 7rem;
  margin-right: 1rem;
}
.sidebar .fa-question {
  margin-left: 8rem;
  margin-right: 1.9rem;
}
.sidebar .fa-graduation-cap {
  margin-left: 8rem;
  margin-right: 0.4rem;
}
.sidebar .fa-chalkboard-user {
  margin-left: 11rem;
  margin-right: 0.2rem;
}
.sidebar .fa-headset {
  margin-left: 11rem;
  margin-right: 0.6rem;
}
.sidebar header .toggle-icon {
  position: absolute;
  margin-top: 30rem;
  top: 50%;
  right: -2.5rem;
  transform: translateY(-50%);
  height: 3rem;
  width: 3rem;
  background-color: var(--eventSideTabBgcolor);
  color: var(--black);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  cursor: pointer;
  transition: var(--tran-05);
}
.sidebar .toggle-icon.open {
  transform: translateY(-50%) rotate(180deg);
}
.sidebar .toggle-icon.close {
  transform: translateY(-50%) rotate(0deg);
  top: 50% !important;
}
body.dark .sidebar header .toggle {
  color: var(--text-color);
}
.sidebar .menu {
  margin-top: 4rem;
}
.sidebar li.search-box {
  border-radius: 0.6rem;
  background-color: var(--primary-color-light);
  cursor: pointer;
  border: 0.1rem solid #dadce0;
  transition: var(--tran-05);
}
.sidebar li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border-radius: 0.6rem;
  font-size: 1.7rem;
  font-weight: 500;
  transition: var(--tran-05);
}
.sidebar li a {
  background-color: transparent;
  display: flex;
  align-items: center;
  border-radius: 0.6rem;
  text-decoration: none;
  transition: var(--tran-03);
  margin-right: 1.5rem;
  color: var(--black) !important;
  font-size: 2.5rem;
}
.sidebar li a:hover {
  background-color: #4344471c;
  backdrop-filter:var(--sidebarHoverFilter);
  box-shadow: var(--sidebarHoverShadow);
  color: var(--primary-color);
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--black);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text {
  color: var(--black);
}
.sidebar .menu-bar {
  height: calc(100% - 5.5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar {
  display: none;
}
.sidebar .menu-bar .mode {
  border-radius: 0.6rem;
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  cursor: pointer;
}
.toggle-switch .switch {
  position: relative;
  height: 2.2rem;
  width: 4rem;
  border-radius: 2.5rem;
  background-color: var(--black);
  transition: var(--tran-05);
}
.switch::before {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  background-color: var(--black);
  transition: var(--tran-04);
}
body.dark .switch::before {
  left: 2rem;
}
.home {
  position: absolute;
  top: 0;
  top: 0;
  left: 25rem;
  height: 100vh;
  width: calc(100% - 25rem);
  background-color: var(--black);
  transition: var(--tran-05);
}
.home .text {
  font-size: 3rem;
  font-weight: 500;
  color: var(--black);
  padding: 1.2rem 6rem;
}
.sidebar.close ~ .home {
  left: 7.8rem;
  height: 100vh;
  width: calc(100% - 7.8rem);
}
body.dark .home .text {
  color: var(--black);
}
.text-logo-a {
  text-decoration: none !important;
  color: var(--black);
}
/* Sidebar ends  here*/

/* Events starts  */
.move-to-left {
  transform: translateX(-40rem);
}
.move-to-left-partly {
  transform: translateX(-20rem);
}
.event-sidebar {
  height: 100%;
  width: 40rem;
  position: fixed;
  z-index: 1000;
  right: -40rem;
  background: var(--eventSideBgcolor);
  backdrop-filter: var(--eventSideFilter);
  box-shadow:var(--eventSideShadow);
  transition: transform 0.7s ease-in-out;
  margin-top: -2rem;
}

.announcement-icon {
  margin-top: 4rem;
  margin-left: 0.5rem;
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  color: var(--black);
  cursor: pointer;
}
.container-liner {
  margin-left: 1rem;
}

.event-sidebar-tab {
  margin-top: 22rem;
  height: 10rem;
  width: 4rem;
  position: fixed;
  z-index: 1000;
  right: 0;
  background: var(--eventSideTabBgcolor);
  border-top-left-radius: 2rem; /* Set only left top corner radius */
  border-bottom-left-radius: 2rem; /* Set only left bottom corner radius */
  backdrop-filter: var(--eventSideTabFilter);
  box-shadow: var(--eventSideTabShadow);
  transition: transform 0.7s ease-in-out;
}


#sidebar-tab-text {
  width: 40rem;
}
.vertical-text {
  transform: rotate(90deg);
  transform-origin: left 2rem;
  vertical-align: middle;
}
.arrow {
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  width: 3.2rem;
  height: 2rem;
  z-index: 1;
  display: "block";
  margin-left: 25rem;
  margin-top: -0.5rem;
}
.arrow:after,
.arrow:before {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  transition: all 0.25s ease-in-out;
  border-radius: 1rem;
  background: var(--black);
  width: 1.6rem;
  height: 0.32rem;
  top: 1.44rem;
}
.arrow:after {
  transform: rotate(44deg);
  left: 0.32rem;
}
.arrow:before {
  right: 0.32rem;
  transform: rotate(-44deg);
}
.arrow.active:after {
  transform: rotate(-44deg);
}
.arrow.active:before {
  transform: rotate(44deg);
}
/* event bar content  */
.post-module {
  position: relative;
  z-index: 1;
  display: block;
  background: rgb(0 0 0 / 47%);
backdrop-filter: var(--eventPostFilter);
  min-width: 27rem;
  min-height: 37rem;
  max-height: 37rem;
  box-shadow: var(--eventPostShadow);
  /* transition: box-shadow 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); */
}
.post-module:hover {
  box-shadow: var(--eventPostHoverShadow);
}
.post-module:hover .thumbnail img {
  /* -webkit-transform: scale(1.1); */
  /* -moz-transform: scale(1.1); */
  transform: scale(1.5);
  transition: box-shadow 0.3s ease-out;
  /* opacity: 0.6; */
}
.thumbnail {
  background: black;
  height: 24rem;
  overflow: hidden;
}
.thumbnail .date {
  position: absolute;
  top: 0;
  left: 1rem;
  z-index: 1;
  background: var(--event-theme);
  width: 6.5rem;
  height: 6.5rem;
  padding: 1rem 0;
  border-radius: 0;
  color: white;
  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
}
.thumbnail .date .day {
  font-size: 2rem;
}
.thumbnail .date .month {
  font-size: 1rem;
  text-transform: uppercase;
}
.thumbnail img {
  display: block;
  width: 120%;
  height: 24rem;
  background: center center no-repeat;
}
.post-content {
  position: absolute;
  bottom: 0;
  background: var(--eventPostBgColor);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(22px);
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  /* transition: transform 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05); */
  height: 22rem;
}
/* .post-module:hover .post-content {
  transform: translateY(-5rem);
} */
.post-content .title {
  margin: 0;
  padding: 0 0 1rem;
  color: dark_gray;
  font-size: 2.6rem;
  font-weight: 700;
}
.post-content .sub_title {
  margin: 0;
  padding: 0 0 2rem;
  color: #e74c3c;
  font-size: 2rem;
  font-weight: 400;
}
.post-content .description {
  display: none;
  color: gray;
  font-size: 1.4rem;
  line-height: 1.8em;
}
.post-content .post-meta {
  position: absolute; /* Make it absolute */
  bottom: 10px; /* Adjust the distance from the bottom */
  left: 10px;
  margin: 8rem 0 0;
  color: rgb(255, 255, 255);
}
.post-content .post-meta .timestamp {
  margin: 0 2rem 0 0;
  color: white;
}
.post-content .post-meta a {
  color: lightgrey;
  text-decoration: none;
}
.post-module:hover + .hover .post-content .description {
  display: block !important;
  height: auto !important;
  opacity: 1 !important;
}
.container {
  min-width: 64rem;
  margin: 0 auto;
  /* height: 20rem; */
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.column {
  margin-left: 1.5rem;
  width: auto;
  /* padding: 0 2.5rem; */
  float: left;
  /* padding-bottom: 10rem; */
}
.column .box-size {
  width: 100%;
  box-sizing: border-box;
}
.column .demo-title {
  margin: 4.5rem 0 2.5rem;
  color: gray;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
/* .info {
  width: 30rem;
  margin: 5rem auto;
  text-align: center;
} */
.info h1 {
  margin: 0 0 1.5rem;
  padding: 0;
  font-size: 2.4rem;
  font-weight: bold;
  color: grey;
}
.info span {
  color: gray;
  font-size: 1.2rem;
}
.info span a {
  color: black;
  text-decoration: none;
}
.info span .fa {
  color: #e74c3c;
}
.eventbtn {
  background-color: var(--event-theme);
  padding: 1rem 2rem;
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 10rem;
}

.eventbtn::before,
.eventbtn::after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
  color: white !important;
  transition: width 0.3s;
}
.eventbtn:hover{
  background: black;
  color: white !important;
  transform: scale(1.1);
}
/* .eventbtn::after {
  left: auto;
  right: 0;
}
.eventbtn:hover::before,
.eventbtn:hover::after {
  width: 50%;
} */
.event-searchbar {
  width: 28rem;
  border-radius: 2rem;
  /* background-color: rgb(33 32 39 / 69%); */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: inset 0px 0px 40px rgb(102 101 101 / 45%);
  padding: 0.5rem 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 3rem 0rem 0rem 0rem;
}

.event-searchbar input {
  width: 100%;
  font-size: 1.8rem;
  color: var(--black);
  background: none;
}

.event-searchbar button {
  background: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--black);
}

.event-searchbar button:hover {
  color: var(--main-color);
}
.event-nofound {
  color: var(--black);
  font-size: 3rem;
  text-align: center;
}
/* Event sidebar ends  here*/

/* Homepage css starts here  */
/* body.dark .background-container::before {
  content: "";
  background-image: url(./components/images/window-dark.jpg);
  background: rgba(23, 23, 23, 0.7);
  background-size: 100%;
  background-position: center;
  filter: blur(15px) brightness(0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
} */
.background-container::before {
  content: "";
  background-image: var(--bgImage);
  /* background: rgb(255, 255, 255); */
  background-size: 100%;
  background-position: center;
  filter: var(--bgImageFilter);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* .section {
  position: relative;
  overflow: hidden;
} */


/* .home-grid > section {
  height: 100vh;
  scroll-snap-align: start;
  overflow: auto;
} */
.home-grid{
  margin-top: 8.5rem;
}
.home-grid .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 27.5rem);
  gap: 3.5rem;
  justify-content: center;
  align-items: flex-start;
}
.line {
  height: 0.25rem;
  margin-left: -2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: var(--line);
  background-size: 1.6rem 0.2rem, 100% 0.2rem;
}
.roadmap-box .flex a{
  width :65%;
  height: 14rem;
  margin-bottom: 0.5rem;
  padding: 0rem;
  margin-left: 15rem;
}
/* .fa-route{
  font-size: 3rem;
  margin-left: -2rem;
}
.fa-wave-square{
  font-size: 3rem;
  height: 3rem;
  margin-left: -1rem;
} */
.roadmap-box .roadmap{
  font-size: 1.5rem;
  /* margin-left: -1rem; */
}
.roadmap-box .fa-html5{
  font-size: 5rem;
  background: white ;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  /* margin-left: -1rem; */
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-html5{
  font-size: 5rem;
   background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-css3{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-css3{
  font-size: 5rem;
   background: linear-gradient(to top right, #3496e7 27%, rgb(38, 63, 228) 100%); 
  
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-js{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-js{
  font-size: 5rem;
   background: linear-gradient(to top right, #fdcc00 27%, #fdcc00 100%); 
   margin-left: 12rem;
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-code{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-code{
  font-size: 5rem;
   background: linear-gradient(to top right, #7509f6 27%, #7509f6 100%); 
  
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-leaf{
  margin-top: 1rem;
  font-size: 5rem;
  /* margin-bottom: 1rem; */
  background: white ;
  /* margin-left: 1.5rem; */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-leaf{
  font-size: 5rem;
   background: linear-gradient(to top right, #09f615 27%, #60f609 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .express{
  font-size: 2.5rem;
  margin-top: -3.5rem;
  padding-bottom: 1rem;
  margin-left: 0.8rem;
}
.roadmap-box .dotnet{
  font-size: 1.8rem;
  margin-top: -3.5rem;
  padding-bottom: 1rem;
  margin-left: 0.5rem;
}
.roadmap-box .fa-circle{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-circle{
  font-size: 5rem;
   background: linear-gradient(to top right, gray 27%, gray 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-react{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-react{
  font-size: 5rem;
   background: linear-gradient(to top right, aqua 27%, aqua 100%);; 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-node{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-node{
  font-size: 5rem;
   background: linear-gradient(to top right, #78b743 27%, #78b743 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .flex a .fa-python{
  font-size: 5rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-python{
  font-size: 5rem;
  background: radial-gradient(circle farthest-corner at top left, #386E9D 48%, #FFCA1D 67%);
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .fa-java{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-java{
  font-size: 5rem;
   background: linear-gradient( #ad2300 50%, rgb(0, 14, 103) 50%); 
  
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.roadmap-box .flex a .fa-gem{
  font-size: 5rem;
  background: white ;
  margin-left: 12rem;
  /* margin-bottom: 0rem; */
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.roadmap-box .flex a:hover .fa-gem{
  font-size: 5rem;
   background: linear-gradient(to right bottom, #bd0e0e 27%, #bd0e0e 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-html5{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-html5{
  font-size: 6rem;
   background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-css3{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-css3{
  font-size: 6rem;
   background: linear-gradient(to top right, #3496e7 27%, rgb(38, 63, 228) 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-js{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-js{
  font-size: 6rem;
  background:#FDCC00;
  -webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}

.fa-bootstrap{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-bootstrap{
  font-size: 6rem;
   background: linear-gradient(to top right, #7509f6 27%, #7509f6 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-react{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-react{
  font-size: 6rem;
   background: linear-gradient(to top right, aqua 27%, aqua 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-file-code{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-file-code{
  font-size: 6rem;
   background: linear-gradient(to top right, #0076b7 27%, #23a8f2 100%); 
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-database{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-database{
  font-size: 6rem;
  background: linear-gradient(to top right, #db7533 27%, #db7533 100%); 
  background-repeat: no-repeat;
  background-position: left center;
-webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
}
.fa-python{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-python{
  font-size: 6rem;
  background: #386E9D;
  background: radial-gradient(circle farthest-corner at top left, #386E9D 48%, #FFCA1D 67%);
  
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.fa-globe{
  font-size: 6rem;
  background: white ;
/* background: linear-gradient(to top right, #f8a413 27%, rgba(228, 77, 38, 1) 100%); */
-webkit-background-clip: text;
/* -webkit-text-fill-color: transparent; */
}
.cheatsheet-box .flex a:hover .fa-globe{
  font-size: 6rem;
  background: #1F7DCF;
  background: repeating-radial-gradient(circle farthest-corner at center center, #1F7DCF 44%, #1AA3FF 89%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}
.home-grid .box-container .cheatsheet-box {
  position: relative;
  background: #ffffff05;
  backdrop-filter: var(--quickBoxFilter);
  box-shadow: var(--quickBoxShadow);
  border-radius: 0.5rem;
  padding: 2rem;
  overflow: hidden;
  width: 105rem;
  height: auto;
  margin-left: -37.2rem;
  margin-top: 20rem;
}
.home-grid .box-container .cheatsheet-box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;

  /* transform: rotate(-45deg); */
}
.home-grid .box-container .roadmap-box {
  position: relative;
  background: #ffffff05;
  backdrop-filter: var(--quickBoxFilter);
  box-shadow: var(--quickBoxShadow);
  border-radius: 0.5rem;
  padding: 2rem;
  overflow: hidden;
  width: 105rem;
  height: 63rem;
  margin-left: -37.2rem;
  margin-top: 20rem;
}
.home-grid .box-container .roadmap-box::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  gap: 15rem;
  /* transform: rotate(-45deg); */
}
.home-grid .box-container .notes-box{
  align-items: center;
  justify-content: center;
  margin-left: -22rem;
  margin-top: 5rem;
  position: relative;
  background: #ffffff05;
  backdrop-filter: var(--quickBoxFilter);
  box-shadow: var(--quickBoxShadow);
  border-radius: 0.5rem;
  padding: 2rem;
  overflow: hidden;
  width: 50rem;
  height: 33rem;
}
.home-grid .box-container .notes-box::before{
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
.home-grid .box-container .playground-box{
  margin-top: 5rem;
  position: relative;
  background: #ffffff05;
  backdrop-filter: var(--quickBoxFilter);
  box-shadow: var(--quickBoxShadow);
  border-radius: 0.5rem;
  padding: 2rem;
  overflow: hidden;
  width: 50rem;
  height: 33rem;
}
.home-grid .box-container .playground-box::before{
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}
.home-grid .box-container .cheatsheet-box .title {
  font-size: 2rem;
  color: var(--black);
  text-transform: capitalize;
}
.home-grid .box-container .roadmap-box .title {
  font-size: 2rem;
  color: var(--black);
  text-transform: capitalize;
}

.home-grid .box-container .cheatsheet-box .flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
}
.home-grid .box-container .roadmap-box .flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
}
.home-grid .box-container .flex a {
  box-shadow: var(--homeGBFa);
  backdrop-filter: var(--homeGBFaRoadFilter);
  border-radius: 0.5rem;
  padding: 4rem 4rem;
  padding-top: 3rem;
  font-size: 1.5rem;
}
.home-grid .box-container .roadmap-box .flex a {
  box-shadow: var(--homeGBFRoadShadow);
  backdrop-filter: var(--homeGBFaRoadFilter);
  border-radius: 0.5rem;
  padding: 2rem 2rem;
  padding-top: 0.2rem;
  font-size: 1.5rem;
      margin-left: 17rem;
}


.home-grid .box-container .flex a i {
  margin-right: 1rem;
  color: var(--black);
}

.home-grid .box-container .flex a span {
  color: var(--light-color);
}

.home-grid .box-container .flex a:hover {
  background: rgba(229, 192, 192, 0.1);
}

.home-grid .box-container .flex a:hover span {
  color: var(--black);
}

.home-grid .box-container .flex a:hover i {
  color: var(--black);
}
.home-grid .box-container .tutor {
  padding: 1rem 0;
  font-size: 1.8rem;
  background: #BDBDBD;
  background: repeating-radial-gradient(circle farthest-corner at center center, #BDBDBD 0%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 2;
}
body.dark .home-grid .box-container .tutor {
  padding: 1rem 0;
  font-size: 1.8rem;
  background: #BAE4FF;
  background: linear-gradient(to top right, #BAE4FF 27%, #1E7CB3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 2;
}

.home-grid .box-container .likes {
  background: #BDBDBD;
  background: repeating-radial-gradient(circle farthest-corner at center center, #BDBDBD 0%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7rem;
  margin-top: 1.5rem;
}

body.dark .home-grid .box-container .likes {
  padding: 1rem 0;
  font-size: 1.8rem;
  background: #BAE4FF;
  background: linear-gradient(to top right, #BAE4FF 27%, #1E7CB3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 2;
}
.home-grid .box-container .likes span {
  color: var(--main-color);
}

.title-xclusive {
  font-size: 2.5rem;
  color: var(--black);
  text-transform: capitalize;
  /* text-shadow: 0rem 0rem 0.4rem rgba(255, 255, 255, 0.7); */
}
.glowing {
  color: white;
  text-shadow: 0rem 0rem 0.5rem rgba(255, 255, 255, 0.7);
  font-size: 2rem;
}

/* CSS */
.button-56 {
  align-items: center;
  background-color: var(--b56BgColor);
  border: var(--b56Border);
  border-radius: 0.8rem;
  box-sizing: border-box;
  color: white;
  text-shadow: 0rem 0rem 0.5rem rgba(255, 255, 255, 0.5);
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  height: 4.8rem;
  justify-content: center;
  line-height: 2.4rem;
  max-width: 100%;
  white-space: nowrap;

  position: relative;
  /* text-align: center; */
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

}

.button-56:after {
  background-color: black;
  border-radius: 0.8rem;
  content: "";
  display: block;
  height: 4.8rem;
  left: 0;
  width: 100%;
  position: absolute;
  top: -0.2rem;
  transform: translate(0.8rem, 0.8rem);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

hr .pacman {
  border: 0;
  height: 2.5rem;
  background-image: url("http://www.formget.com/wp-content/uploads/2014/12/type_3.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 20rem;
  margin-left: 2.5rem;
  margin-top: 1rem;
}

/* Homepage css ends here  */
/* About css starts here */
.about{
  margin-top: 10rem;
}
.about .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.about .row .image {
  flex: 1 1 40rem;
}

.about .row .image img {
  width: 100%;
  height: 50rem;
}

.about .row .content {
  flex: 1 1 40rem;
}

.about .row .content p {
  font-size: 1.7rem;
  line-height: 2;
  color: var(--whyUs);
  padding: 1rem 0;
}

.about .row .content h3 {
  font-size: 3rem;
  color: var(--black);
  text-transform: capitalize;
}

.about .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  gap: 5rem;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3rem;
}

.about .box-container .box {
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.about .box-container .box i {
  font-size: 4rem;
  color: var(--main-color);
}

.about .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  margin-bottom: 0.3rem;
}

.about .box-container .box p {
  font-size: 1.7rem;
  color: var(--light-color);
}
section .comments {
  padding: 3rem 0rem 2rem 0rem;
  margin: 0 auto;
  max-width: 125rem;
}
.star-rating {
  display: flex;
  color: var(--orange);
  padding: 0.3rem 2.3rem;
  margin: 0.5rem;
  justify-content: center;
  align-items: center;
}

.star1,
.star2,
.star3,
.star4,
.star5 {
  margin-right: 1rem;
  font-size: 3rem;
  cursor: pointer;
}

.fa-chevron-left {
  transform: rotate(90deg);
  margin-left: 50rem;
}
.fa-chevron-right {
  transform: rotate(-90deg);
}
.reviews .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: stretch;
}

.reviews .box-container .box {
  border-radius: 0.5rem;
  background-color: var(--white);
  backdrop-filter:var(--reviewBgColor);
  box-shadow: var(--reviewBShadow);
  padding: 2rem;
  /* transition: 0.3s ease-in-out; */
  display: flex;
  flex-direction: column; /* Set the flex direction to column */
  justify-content: space-between;
}

.reviews .box-container .box p {
  line-height: 1.7;
  font-size: 1.7rem;
  color: var(--whyUs);
}
/* .reviews .box-container:hover .box {
  filter: blur(15px);
  transform: scale(0.5);
  opacity: 0.5;
} */
/* .reviews .box-container .box:hover {
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  box-shadow: var(--reviewHoverShadow);
} */
.reviews .box-container .box .student {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.reviews .box-container .box .student img {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 50%;
}

.reviews .box-container .box .student h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.3rem;
}

.reviews .box-container .box .student .stars {
  font-size: 1.5rem;
  color: var(--orange);
}
/* about cards css starts here  */

@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap");

.about .dev {
  color: #2b2c48;
  font-family: "Jost", sans-serif;
  /* background-image: url(https://images.unsplash.com/photo-1566738780863-f9608f88f3a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2378&q=80); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
}

.card {
  max-width: 34rem;
  margin: auto;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  background-color: var(--aboutCardBG);
  /* backdrop-filter: var(--reviewBgColor); */
  display: flex;
  transition: 0.3s;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.8rem rgba(255, 255, 255, 0.2);
}
.card {
  overflow: hidden;
}
.card[data-state="#about"] {
  height: 53rem;
}
.card[data-state="#contact"] {
  height: 43rem;
}

.card[data-state="#experience"] {
  height: 55rem;
}

.card.is-active {
  .card-header {
    height: 8rem;
  }

  .card-cover {
    height: 10rem;
    top: -5rem;
  }

  .card-avatar {
    transform: none;
    left: 2rem;
    width: 5rem;
    height: 5rem;
    bottom: 1rem;
  }

  .card-fullname,
  .card-jobtitle {
    left: 8.6rem;
    transform: none;
  }

  .card-fullname {
    bottom: 1.8rem;
    font-size: 1.9rem;
  }

  .card-jobtitle {
    bottom: 0rem;
    letter-spacing: 0.1rem;
    font-size: 1rem;
  }
}

.card-header {
  position: relative;
  display: flex;
  height: 20rem;
  flex-shrink: 0;
  width: 100%;
  transition: 0.3s;
  border-bottom: none;
}

.card-cover-mayank {
  background-image: url(https://res.cloudinary.com/dkyrtfk1u/image/upload/v1690369698/i14t3seuzumapysauajm.jpg);
  width: 100%;
  height: 100%;
  position: absolute;
  height: 16rem;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(2rem);
  transform: scale(1.2);
  transition: 0.5s;
}
.card-cover-divya {
  background-image: url(https://res.cloudinary.com/dkyrtfk1u/image/upload/v1692452732/by2nz7yrqcfmnq2nlnu6.jpg);
  width: 100%;
  height: 100%;
  position: absolute;
  height: 16rem;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(2rem);
  transform: scale(1.2);
  transition: 0.5s;
}
.card-cover-darshit {
  background-image: url(http://res.cloudinary.com/dkyrtfk1u/image/upload/v1697349373/sli9ffed1vdqegf1srip.jpg);
  width: 100%;
  height: 100%;
  position: absolute;
  height: 16rem;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(2rem);
  transform: scale(1.2);
  transition: 0.5s;
}
.card-cover-aastha {
  background-image: url(http://res.cloudinary.com/dkyrtfk1u/image/upload/v1708837420/sbga0ipdyfjkd2gvc51l.png);
  width: 100%;
  height: 100%;
  position: absolute;
  height: 16rem;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(2rem);
  transform: scale(1.2);
  transition: 0.5s;
}
.card-cover-kussh {
  background-image: url(https://res.cloudinary.com/dkyrtfk1u/image/upload/v1708844593/x8f1ay9rbefzfzusbbqw.png);
  width: 100%;
  height: 100%;
  position: absolute;
  height: 16rem;
  top: -20%;
  left: 0;
  will-change: top;
  background-size: cover;
  background-position: center;
  filter: blur(2rem);
  transform: scale(1.2);
  transition: 0.5s;
}
.card-avatar {
  width: 10rem;
  height: 10rem;
  box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -6.4rem);
  overflow: hidden;
  transition: transform 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-avatar img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.card-avatar:hover {
  transform: translate(-50%, -6.4rem) scale(1.4);
  transform-origin: center; /* Set the transform origin to center */
}

.card-fullname {
  position: absolute;
  bottom: 0;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  transform: translateY(-1rem) translateX(-50%);
  left: 50%;
}

body.dark .card-fullname {
  position: absolute;
  bottom: 0;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  transform: translateY(-1rem) translateX(-50%);
  left: 50%;
  background: linear-gradient(to top right, #BAE4FF 27%, #1E7CB3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-jobtitle {
  position: absolute;
  bottom: -1rem;
  font-size: 1.1rem;
  white-space: nowrap;
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin: auto;
  left: 50%;
  transform: translateX(-50%) translateY(-0.7rem);

}

body.dark .card-jobtitle {
  position: absolute;
  bottom: -1rem;
  font-size: 1.1rem;
  white-space: nowrap;
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin: auto;
  left: 50%;
  transform: translateX(-50%) translateY(-0.7rem);
  background: linear-gradient(to top right, #BAE4FF 27%, #1E7CB3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-main {
  position: relative;
  flex: 1;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
}

.card-subtitle1 {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
  padding-top: 0.5rem;
  color: var(--black);
}

.card-subtitle2 {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: -3.2rem;
  padding-top: 3.5rem;
  color: var(--black);
}

.card-subtitle3 {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: -1.2rem;
  padding-top: 3.5rem;
  color: var(--black);
}

.card-content {
  padding: 1rem;
}

.card-desc {
  line-height: 1.6;
  color: #636b6f;
  font-size: 1.4rem;
  margin: 0;
  text-align:justify;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}

.card-social {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 3rem;
}
.card-social ul {
  display: flex;
}
.card-social svg {
  display: flex;
  /* fill: rgb(0, 0, 0); */
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  transition: 0.3s;
}
/* .card-social svg:nth-child(1):hover {
  fill: rgb(42, 19, 174);
}

.card-social svg:nth-child(2):hover {
  fill: rgb(174, 19, 19);
} */

.card-social a {
  /* color: #8797a1; */
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.05);
  margin-right: 1rem;
}

.card-social ul li a:hover {
  color: white !important;
  transform: translate(0rem, -0.7rem);
}


.card-social ul li:nth-child(1) a:hover {
  color: none;
  background-color: black;
}

.card-social ul li:nth-child(2) a:hover {
  background-color: #0a66c2;
}

.card-social ul li:nth-child(3) a:hover {
  background: linear-gradient(to right, #870000, #190a05);
}

.card-social ul li:nth-child(4) a:hover {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
}
.card-social ul li:nth-child(5) a:hover {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}
.card-social ul li:nth-child(6) a:hover {
  color: none;
  background-color: black;
}

/* .card-social a:hover svg {
  fill: darken(rgb(248, 248, 248), 20%);
}
.card-social a:last-child {
  margin-right: 0;
} */

.card-buttons {
  display: flex;
  background-color: var(--aboutCardBG);
  margin-top: 1rem;
  margin-bottom: -15rem;
  position: sticky;
  bottom: 0;
  left: 0;
  /* height: 10rem; */
}

.card-buttons button {
  flex: 1 1 auto;
  user-select: none;
  background: 0;
  font-size: 1.3rem;
  border: 0;
  padding: 1rem 0.5rem;
  cursor: pointer;
  color: #5c5c6d;
  transition: 0.3s;
  font-family: "Jost", sans-serif;
  font-weight: 500;
  outline: 0;
  border-bottom: 0.3rem solid transparent;
}

.card-buttons button.is-active,
.card-buttons button:hover {
  color: var(--aboutcardbottomText);
  border-bottom: 0.3rem solid #8a84ff;
  background: linear-gradient(
    to bottom,
    rgba(127, 199, 231, 0) 0%,
    rgba(207, 204, 255, 0.2) 44%,
    rgba(211, 226, 255, 0.4) 100%
  );
}

.card-section {
  display: none;
}

.card-section.is-active {
  display: block;
  animation: fadeIn 0.6s both;
}



.card-timeline {
    margin-top: 3rem;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: auto;
    float: left;
    float: left;
    /* width: 348px; */
    overflow-y: auto;
    height: 343px;
}

.card-timeline-darshit:after {
  background: linear-gradient(
    to top,
    rgba(134, 214, 243, 0) 0%,
    rgba(81, 106, 204, 1) 100%
  );
  content: "";
  left: 4.2rem;
  width: 0.2rem;
  top: 2.5rem;
  height: 190%;
  position: absolute;
}
.card-timeline-divya:after {
  background: linear-gradient(
    to top,
    rgba(134, 214, 243, 0) 0%,
    rgba(81, 106, 204, 1) 100%
  );
  content: "";
  left: 4.2rem;
  width: 0.2rem;
  top: 2.5rem;
  height: 110%;
  position: absolute;
}
.card-timeline-mayank:after {
  background: linear-gradient(
    to top,
    rgba(134, 214, 243, 0) 0%,
    rgba(81, 106, 204, 1) 100%
  );
  content: "";
  left: 4.2rem;
  width: 0.2rem;
  top: 2.5rem;
  height: 150%;
  position: absolute;
}
.card-timeline-aastha:after {
  background: linear-gradient(
    to top,
    rgba(134, 214, 243, 0) 0%,
    rgba(81, 106, 204, 1) 100%
  );
  content: "";
  left: 4.2rem;
  width: 0.2rem;
  top: 2.5rem;
  height: 25%;
  position: absolute;
}
.card-timeline-kussh:after {
  background: linear-gradient(
    to top,
    rgba(134, 214, 243, 0) 0%,
    rgba(81, 106, 204, 1) 100%
  );
  content: "";
  left: 4.2rem;
  width: 0.2rem;
  top: 2.5rem;
  height: 140%;
  position: absolute;
}

.card-item {
  position: relative;
  padding-left: 6rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
  padding-top: 1.8rem;
  z-index: 1;
}

.card-item:last-child {
  padding-bottom: 0.5rem;
}

.card-item:after {
  content: attr(data-year);
  width: 1rem;
  position: absolute;
  top: 2.2rem;
  left: 3.8rem;
  width: 1rem;
  height: 1rem;
  line-height: 0.6;
  border: 0.3rem solid transparent;
  font-size: 1.1rem;
  text-indent: -3.5rem;
  border-radius: 50%;
  color: rgba(134, 134, 134, 0.7);
  background: #516acc;
  background: linear-gradient( to bottom, lighten(#516acc, 20%) 0%, #516acc 100% ) !important;
  box-shadow: 1px 1.5px 12px rgba(0, 0, 0, 0.457);
}

.card-item-title {
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

body.dark .card-item-title {
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(to top right, #BAE4FF 27%, #1E7CB3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-item-desc {
  font-size: 1.3rem;
  color: #6f6f7b;
  line-height: 1.5;
  font-family: "DM Sans", sans-serif;
}

.card-contact-wrapper {
  margin-top: 2rem;
}

.card-contact {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  color: #6f6f7b;
  font-family: "DM Sans", sans-serif;
  line-height: 1.6;
  cursor: pointer;
}

.card-contact:hover{
  background:linear-gradient(to top right, #BAE4FF 27%, #1E7CB3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-contact + .card-contact {
  margin-top: 1.6rem;
}

.card-contact svg {
  flex-shrink: 0;
  width: 3rem;
  min-height: 3.4rem;
  margin-right: 1.2rem;
  transition: 0.3s;
  padding-right: 1.2rem;
  border-right: 0.1rem solid #dfe2ec;
}


.contact-me {
  border: 0;
  outline: none;
  background: linear-gradient(
    to right,
    rgba(83, 200, 239, 0.8) 0%,
    rgba(81, 106, 204, 0.8) 96%
  );
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  color: #fff;
  padding: 1.2rem 1.6rem;
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 2.5rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
}
/* About card css ends here  */
/* About css ends  here */
/* Watch video page css starts here  */
.video-container{
  /* align-items: center; */
  /* width: 120rem; */
  text-align: center;
  margin-top: 2rem;
  background-color: var(--white);
  backdrop-filter: blur(100px);
  box-shadow: var(--commentShadow);
}
/* Watch video page css ends here  */
/* Courses css starts  here*/
.courses{
  margin-top: 10rem;
}
.courses ul#tabs {
  list-style-type: none;
  padding: 0;
  text-align: center;
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 1rem;
}
.courses ul#tabs li {
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 4rem;
  display: inline-block;
  background-color:rgb(255 255 255 / 0%);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 2rem rgb(0 0 0 / 19%);
  /* border-bottom: solid 5px rgb(37, 69, 128); */
  /* padding: 5px 20px; */
  margin-bottom: -10px;
  color: #fff;
  cursor: pointer;
  width: 16rem;
  font-size:1.9rem;
  margin-bottom: 1rem;
  color: var(--black);
  border-radius: 1rem;
 
}
.courses ul#tabs li:hover {
  background-color: var(--primary-color);
  color: white;
}
.courses ul#tabs li.active {
  /* backdrop-filter: blur(20px); */
  box-shadow: 0 0 2rem rgb(0 0 0 / 12%);
  background-color: var(--semActive);
  /* backdrop-filter: blur(10px); */
  color: white;
}

.courses ul#tab li {
  display: none;
}
.courses ul#tab li.active {
  display: block;
}
.courses .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 11rem;
  justify-content: center;
  margin-left: 1rem;
}
.courses .inline-btn {
  width: 20rem;
  height: 4rem;
  display: flex;
  margin-right: -22.5rem;
  padding-bottom: 3rem;
  padding-top: 0.6rem;
  justify-content: center;
  align-items: stretch;
  margin-top: 4rem;
}
.courses .box-container .box {
  /* border-radius: 4rem; */
  background-color: #5badad12;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: var(--reviewBShadow);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  border-radius: 2rem;
  justify-content: center;
}


.courses .box-container .box .tutor {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: -1.5rem;
  margin-top: -3.5rem;
}

.courses .box-container .box .tutor img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
}

.courses .box-container .box .tutor h3 {
  font-size: 1.8rem;
  color: var(--black);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.courses .box-container .box .tutor span {
  font-size: 1.3rem;
  color: var(--light-color);
}

.courses .box-container .box .thumb {
  position: relative;
}

.courses .box-container .box .thumb span {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.5rem;
}

.courses .box-container .box .thumb img {
  width: 100%;
  height: 20rem;
  object-fit: fill;
  border-radius: 0.5rem;
}

.courses .box-container .box .title {
  font-size: 2rem;
  color: var(--black);
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  text-align: center !important;
  margin-left: 0.2rem;
}

/* Courses css ends  here*/

/* Playlist css starts here */
.playlist-details{
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
 min-width: fit-content;
}
.playlist-details .row {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  gap: 3rem;
  flex-wrap: wrap;
  background-color: var(--bgTransperent);
  backdrop-filter: blur(100px);
  box-shadow: var(--reviewBShadow);
  padding: 2rem;
  border-radius: 2rem;
}

.playlist-details .row .column {
  flex: 1 1 40rem;
}

.playlist-details .row .column .save-playlist {
  margin-bottom: 1.5rem;
visibility: hidden;
}

.playlist-details .row .column .save-playlist button {
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 2rem rgb(0, 0, 0);
  padding: 1rem 1.5rem;
  cursor: pointer;
  visibility: hidden;
}

.playlist-details .row .column .save-playlist button i {
  font-size: 2rem;
  color: var(--black);
  margin-right: 0.8rem;
}

.playlist-details .row .column .save-playlist button span {
  font-size: 1.8rem;
  color: var(--light-color);
}

.playlist-details .row .column .save-playlist button:hover {
  background-color:rgba(119, 117, 119, 0.1);
  color: var(--white)
}

.playlist-details .row .column .save-playlist button:hover i {
  color: var(--white);
}

.playlist-details .row .column .save-playlist button:hover span {
  color: var(--white);
}

.playlist-details .row .column .thumb {
  position: relative;
}

.playlist-details .row .column .thumb span {
  font-size: 1.8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 1.5rem;
}

.playlist-details .row .column .thumb img {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  cursor:pointer;
}
.playlist-details .row .column .thumb img:hover{
  backdrop-filter: blur(20rem);
  /* background-color:var(--bgTransperent); */
  box-shadow: var(--reviewBShadow);
}

.playlist-details .row .column .tutor {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  visibility: hidden;
}

.playlist-details .row .column .tutor img {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  object-fit: cover;
}

.playlist-details .row .column .tutor h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.playlist-details .row .column .tutor span {
  font-size: 1.5rem;
  color: var(--light-color);
}

.playlist-details .row .column .details h3 {
  font-size: 2rem;
  color: var(--black);
  text-transform: capitalize;
}

.playlist-details .row .column .details p {
  padding: 1rem 0;
  line-height: 2;
  font-size: 1.8rem;
  color: var(--light-color);
  text-align: justify;
}

.playlist-videos .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.playlist-videos .box-container .box {
  text-align: center;
  background-color: var(--bgTransperent);
  height: auto;
  backdrop-filter: blur(20px);
  box-shadow: var(--reviewBShadow);
  border-radius: 0.5rem;
  padding: 0rem;
  position: relative;
  border-radius: 2rem;
}

.playlist-videos .box-container .box i {
  position: absolute;
  left: 0rem;
  right: 0rem;
  height: 20rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  color: #fff;
  display: none;
}

.playlist-videos .box-container .box:hover i {
  display: flex;
  margin-top: 2rem;
  width: 90%;
  margin-left: 2rem;
}

.playlist-videos .box-container .box img {
  margin-left: 2rem;
  margin-top: 2rem;
  width: 90%;
  height: auto;
  object-fit:fill;
  border-radius: 0.5rem;
}

.playlist-videos .box-container .box img:hover{
  backdrop-filter: blur(20rem);
  /* background-color:var(--bgTransperent); */
  box-shadow: var(--reviewBShadow);
}

.playlist-videos .box-container .box h3 {
  margin-top: 1.5rem;
  font-size: 1.8rem;
  color: var(--black);
  margin-bottom: 2rem;
}

.playlist-videos .box-container .box:hover h3 {
  color: var(--pBBHover);
}
/* Playlist css ends here  */

/* Watchvideo css starts here  */
.watch-video .video-container {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
  text-align:center;
}

.watch-video .video-container .video {
  position: relative;
  margin-bottom: 1.5rem;
}

.watch-video .video-container .video video {
  border-radius: 0.5rem;
  width: 100%;
  object-fit: contain;
  background-color: #000;
}

.watch-video .video-container .title {
  font-size: 2rem;
  color: var(--black);
}

/* .watch-video .video-container .info {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: -7rem;
  border-bottom: var(--border);
  padding-bottom: 1.5rem;
  gap: 2.5rem;
  align-items: center;
} */

/* .watch-video .video-container .info p {
  font-size: 1.6rem;
} */

.watch-video .video-container .info p span {
  color: var(--light-color);

    font-size: 1.2rem;
    margin-right: 1rem;
    color: var(--black);

}
.fa-calendar:before {
  content: "\f073";
  font-size: 1.2rem;
  margin-right: 1rem;
  color: var(--black);
}

/* .watch-video .video-container .info i {
  margin-right: 1rem;
  color: var(--main-color);
} */

.watch-video .video-container .tutor {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.watch-video .video-container .tutor img {
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  object-fit: cover;
}

.watch-video .video-container .tutor h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
  margin-right: 5rem;
}

.watch-video .video-container .tutor span {
  font-size: 1.5rem;
  color: var(--light-color);
  float: left;
}

.watch-video .video-container .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.watch-video .video-container .flex button {
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  cursor: pointer;
  background-color: var(--light-bg);
}

.watch-video .video-container .flex button i {
  margin-right: 1rem;
  color: var(--black);
}

.watch-video .video-container .flex button span {
  color: var(--light-color);
}

.watch-video .video-container .flex button:hover {
  background-color: var(--black);
}

.watch-video .video-container .flex button:hover i {
  color: var(--white);
}

.watch-video .video-container .flex button:hover span {
  color: var(--white);
}

.watch-video .video-container .description {
  line-height: 1.5;
  font-size: 1.7rem;
  color: var(--light-color);
  margin-top: 2rem;
}

.comments .add-comment {
  background-color: var(--white);
  backdrop-filter: blur(20px);
  box-shadow: var(--commentShadow);
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 3rem;
  /* width: 110rem; */
}

.comments .add-comment h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 1rem;
}

.comments .add-comment textarea {
  height: 20rem;
  resize: none;
  background-color: var(--light-bg);
  border-radius: 0.5rem;
  border: var(--border);
  padding: 1.4rem;
  font-size: 1.8rem;
  color: var(--black);
  width: 97%;
  margin: 0.5rem 0;
}

.comments .box-container {
  display: grid;
  gap: 2.5rem;
  background-color: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
}

.comments .box-container .box .user {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.comments .box-container .box .user img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.comments .box-container .box .user h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.comments .box-container .box .user span {
  font-size: 1.5rem;
  color: var(--light-color);
}

.comments .box-container .box .comment-box {
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 1rem 1.5rem;
  white-space: pre-line;
  margin: 0.5rem 0;
  font-size: 1.8rem;
  color: var(--black);
  line-height: 1.5;
  position: relative;
  z-index: 0;
}

.comments .box-container .box .comment-box::before {
  content: "";
  position: absolute;
  top: -1rem;
  left: 1.5rem;
  background-color: var(--light-bg);
  height: 1.2rem;
  width: 2rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
/* Watchvideo css ends here  */

/* Teacher page starts here  */
.teachers .search-tutor {
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.teachers .search-tutor input {
  width: 100%;
  background: none;
  font-size: 1.8rem;
  color: var(--black);
}

.teachers .search-tutor button {
  font-size: 2rem;
  color: var(--black);
  cursor: pointer;
  background: none;
}

.teachers .search-tutor button:hover {
  color: var(--main-color);
}

.teachers .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.teachers .box-container .box {
  background-color: var(--white);
  border-radius: 5rem 5rem 0rem 0rem;
  box-shadow: 0rem 0.5rem 20rem 1.2rem inset #308025;
  padding: 2rem;
}

.teachers .box-container .offer {
  width: 100%;
  height: fit-content;
  text-align: center;
}

.teachers .box-container .offer h3 {
  font-size: 2.5rem;
  color: var(--black);
  text-transform: capitalize;
  padding-bottom: 0.5rem;
}

.teachers .box-container .offer p {
  line-height: 1.7;
  padding: 0.5rem 0;
  color: var(--light-color);
  font-size: 1.7rem;
}

.teachers .box-container .box .tutor {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.teachers .box-container .box .tutor img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.teachers .box-container .box .tutor h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.teachers .box-container .box .tutor span {
  font-size: 1.6rem;
  color: var(--light-color);
}

.teachers .box-container .box p {
  padding: 0.5rem 0;
  font-size: 1.7rem;
  color: var(--light-color);
}

.teachers .box-container .box p span {
  color: var(--main-color);
}

.teacher-profile .details {
  text-align: center;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.teacher-profile .details .tutor img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.teacher-profile .details .tutor h3 {
  font-size: 2rem;
  color: var(--black);
}

.teacher-profile .details .tutor span {
  color: var(--light-color);
  font-size: 1.7rem;
}

.teacher-profile .details .flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
}

.teacher-profile .details .flex p {
  flex: 1 1 20rem;
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 1.2rem 2rem;
  font-size: 1.8rem;
  color: var(--light-color);
}

.teacher-profile .details .flex p span {
  color: var(--main-color);
}

.user-profile .info {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.user-profile .info .user {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
}

.user-profile .info .user img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.user-profile .info .user h3 {
  font-size: 2rem;
  color: var(--black);
}

.user-profile .info .user p {
  font-size: 1.7rem;
  color: var(--light-color);
  padding: 0.3rem 0;
}

.user-profile .info .box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.user-profile .info .box-container .box {
  background-color: var(--light-bg);
  border-radius: 0.5rem;
  padding: 2rem;
  flex: 1 1 25rem;
}

.user-profile .info .box-container .box .flex {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.user-profile .info .box-container .box .flex i {
  font-size: 2rem;
  color: var(--white);
  background-color: var(--black);
  text-align: center;
  border-radius: 0.5rem;
  height: 5rem;
  width: 5rem;
  line-height: 4.9rem;
}

.user-profile .info .box-container .box .flex span {
  font-size: 2.5rem;
  color: var(--main-color);
}

.user-profile .info .box-container .box .flex p {
  color: var(--light-color);
  font-size: 1.7rem;
}

/* Teacher css ends here  */

/* Contact css starts here */
.contact .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.contact .row .image {
  flex: 1 1 50rem;
}

.contact .row .image img {
  width: 100%;
}

.contact .row form {
  /* flex: 1 1 30rem; */
  margin-left: 25rem;
  width: 80rem;
  background-color: var(--bgTransperent);
  backdrop-filter: blur(20px);
  box-shadow:var(--commentShadow);
  padding: 4rem;
  text-align: center;
  border-radius: 2rem;
  margin-top: 10rem;
}

.contact .row form h3 {
  margin-bottom: 1rem;
  text-transform: capitalize;
  color: var(--black);
  font-size: 2.5rem;
}

.contact .row form .box {
  width: 100%;
  border-radius: 2rem;
  background-color: var(--light-bg);
  backdrop-filter: blur(20px);
  box-shadow: inset 0px 0px 10px rgba(101, 101, 102, 0.25);
  margin: 1rem -1.8rem;
  padding: 1rem;
  font-size: 1.8rem;
  color: var(--black);
}

.contact .row form textarea {
  height: 20rem;
  resize: none;
}

.contact .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: stretch;
  margin-top: 3rem;
}

.contact .box-container .box {
  text-align: center;
  background-color: var(--white);
  border-radius: 4rem;
  padding: 3rem;
}

.contact .box-container .box i {
  font-size: 3rem;
  color: var(--main-color);
  margin-bottom: 1rem;
}

.contact .box-container .box h3 {
  font-size: 2rem;
  color: var(--black);
  margin: 1rem 0;
}

.contact .box-container .box a {
  display: block;
  padding-top: 0.5rem;
  font-size: 1.8rem;
  color: var(--light-color);
}

.contact .box-container .box a:hover {
  text-decoration: underline;
  color: var(--black);
}
/* Contact css ends here  */
/* Event form css starts here  */
.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}

.custom-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.jethalal {
  width: 100%;
  height: 80%;
}

/* Add this CSS to your component's stylesheet or a separate CSS file */
.jethalal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
}
/* Register and login css starts here  */
.form-container {
  min-height: calc(100vh - 20rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container form {
  background-color: var(--bgTransperent);
  backdrop-filter: blur(20px);
  box-shadow: var(--commentShadow);
  /* border-radius: 4rem; */
  padding: 3rem;
  width: 50rem;
  margin-left: 10rem;
  margin-top: 14rem ;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.register{
  margin-top: 10rem;
  align-items: center;
  justify-content: center;
}
.form-container form h3 {
  font-size: 2.5rem;
  text-transform: capitalize;
  color: var(--black);
  text-align: center;
}

.form-container form p {
  font-size: 1.7rem;
  color: var(--light-color);
  padding-top: 1rem;
}

.form-container form p span {
  color: var(--red);
}

.form-container form .box {
  font-size: 1.8rem;
  color: var(--dropDownColor);
  /* border-radius: 2rem; */
  padding: 1.4rem;
  background-color: var(--light-bg);
  backdrop-filter: blur(20px);
  box-shadow: inset 0px 0px 10px rgba(101, 101, 102, 0.25);
  width: 100%;
  margin: 1rem -0.2rem;
  border-radius: 2rem;
}
img{
  border: none !important;
}
.form-container img {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 1rem;
}
.image-input-container {
  display: flex;
  align-items: center;
}

.profile-pic-preview {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: -2rem;
}
.image-input{
  display: grid !important;
}
.image-input-label {
  font-size: 0.875rem;
  margin-right: 0.625rem;
  cursor: pointer;
}
.box {
  resize: none;
}
/* Register and login css ends here  */

/* Footer css starts here  */


.footer-cta {
  /* border-bottom: 0.1rem solid var(--main-color);  */
  padding-bottom: 0.8rem !important;
  margin-left:20rem
}
.single-cta i {
  color: #1424ff;
  font-size: 3.0rem;
  float: left;
  margin-top: .8rem;
}
.cta-text {
  padding-left: 1.5rem;
  display: inline-block;
}
.cta-text h4 {
  color: var(--black);
  font-size: 2.0rem;
  font-weight: 600;
  margin-bottom: .2rem;
}
.cta-text span {
  color: #757575;
  font-size: 1.5rem;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 33.0rem;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo img {
  max-width: 5rem;
  margin-right: 1rem;
  
}
.footer-logo img {
  display: flex;
  align-items: center; /* Vertically center align items */

}
.footer-text p {
  margin-bottom: 1.4rem;
  font-size: 1.4rem;
  color: #7e7e7e;
  line-height: 2.1rem;
}
.footer-social-icon span {
  color: var(--black);
  display: block;
  font-size: 2.0rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 2.0rem;
}
.footer-social-icon a {
  color: #fff;
  font-size: 1.6rem;
  margin-right: 1.5rem;
}
.footer-social-icon i {
  height: 4.0rem;
  width: 4.0rem;
  text-align: center;
  line-height: 3.8rem;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #5239dd;
}
.footer-widget-heading h3 {
  color: var(--black);
  font-size: 2.0rem;
  font-weight: 600;
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1.5rem;
  height: .2rem;
  width: 5.0rem;
  background: var(--main-color);
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 1.2rem;
}
.footer-widget ul li a:hover {
  color: #ffffff;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 1.4rem 2.8rem;
  background-color: var(--light-bg);
  backdrop-filter: blur(20px);
  box-shadow: var(--subscribeInputShadow);
  border: .1rem solid #2e2e2e;
  color: var(--black);
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: var(--eventSideTabBgcolor);
  padding: 1.3rem 2.0rem;
  border: .1rem solid #08035584;
  top: 0;
}
.subscribe-form button i {
  color: var(--black);
  font-size: 2.2rem;
  transform: rotate(-6deg);
}
.copyright-area {
  /* background: var(--white); */
  /* backdrop-filter: blur(100px); */
  /* box-shadow:inset 0 1rem 9rem 0rem rgb(21 39 94); */
  box-shadow: 0 0rem 61rem 0rem rgb(21 39 94 / 38%);
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;

  padding: 1rem 0;
}
.subscribe-area {
  /* background: var(--white); */
  /* backdrop-filter: blur(100px); */
  /* box-shadow:inset 0 1rem 9rem 0rem rgb(21 39 94); */
  /* box-shadow: 0 0rem 61rem 0rem rgb(21 39 94 / 38%); */
  /* display: flex; */
  /* justify-content: center; Horizontally center the content */
  /* align-items: center; */

  padding: 1rem 0;
}
.copyright-text p {
  margin: 0;
    font-size: 1.5rem;
    color: #878787;
    margin-top: 1rem;
    margin-left: 40rem;
    width: 100%;
    
}
.copyright-text p a {
  color: #12b8ff;
  text-align: center;
}
.footer-menu li {
  display: inline-block;
  margin-left: 2.0rem;
}
.footer-menu li:hover a {
  color: #12b8ff;
}
.footer-menu li a {
  font-size: 1.5rem;
  color: var(--black);
}
.footer-cta .row .col-xl-4:nth-child(1) .single-cta i {
  position: absolute;
  color: #ff0000;
}
.footer-cta .row .col-xl-4:nth-child(1) .single-cta .cta-text {
  padding-left: 3.2rem;
}
.footer-cta .row .col-xl-4:nth-child(2) .single-cta i {
  color: #14ffa7;
}
.footer-cta .row .col-xl-4:nth-child(3) .single-cta i {
  color: #ffd700;
}
.footer-social-icon .social_icon {
  text-align: left;
}
.footer-social-icon .social_icon li {
  float: inherit !important;
  display: inline-block !important;
  width: inherit;
}
.footer-social-icon .social_icon li a i {
  font-size: 1.5rem;
  line-height: 3.9rem;
}
.footer-social-icon .social_icon li a {
  width: 4.0rem;
  height: 4.0rem;
}

.social_icon {
  text-align: right;
  margin: 0;
  margin-top: .3rem;
}
.social_icon li {
  display: inline-block;
  margin: 0 .5rem;
}
.social_icon li a {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: var(--black);
  color: #000;
  text-align: center;
  border-radius: 100%;
  transition: 0.2s ease-in-out;
}
.social_icon li a i {
  font-size: 1.5rem;
  line-height: 2.0rem;
}
.social_icon li:nth-child(1) a {
  background: #4267b2;
  color: var(--black);
}
.social_icon li:nth-child(2) a {
  background: #1da1f2;
  color: var(--black);
}
.social_icon li:nth-child(3) a {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: var(--black);
}
.social_icon li:nth-child(4) a {
  background: #ff0000;
  color: var(--black);
}
.social_icon li:nth-child(1):hover a {
  background: var(--black);
  color: #4267b2;
}
.social_icon li:nth-child(2):hover a {
  background: var(--black);
  color: #1da1f2;
}
.social_icon li:nth-child(3):hover a {
  background: var(--black);
  color: #bc1888;
}
.social_icon li:nth-child(4):hover a {
  background: var(--black);
  color: #ff0000;
}

/* Footer css ends here  */

/* select {
  --selectHover: #308025;
}
option:hover {
  background-color: var(--selectHover);
} */

/* Contributor-teacher.js css starts here  */
.fa-solid {
  align-items: center;
  align-self: center;
}
#header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 2rem;
}

.share {
  width: 4.5rem;
  height: 3rem;
  background-color: #f55e77;
  border: 0;
  border-bottom: 0.4rem solid #c0506a;
  border-radius: 2rem;
  cursor: pointer;
}

.share:active {
  border-bottom: 0;
}

.share i {
  color: #fff;
  font-size: 2rem;
}

h1 {
  font-family: "Jost", sans-serif;
  font-size: 1.7rem;
  color: #f55e77;
  text-transform: uppercase;
  cursor: default;
}
.userlisth1{

  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;

  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient( 248deg, rgb(210 255 0) 0%, rgb(255 0 246) 100% );
  color: #e5895f;
}
#leaderboard {
  width: 100%;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  color: #ffffff;
  cursor: default;
}

tr {
  transition: all 0.2s ease-in-out;
  border-radius: 0.2rem;
}

tr:not(:first-child):hover {
  background-color: #458d3b;
  transform: scale(1.1);
  border-radius: 0.8rem;
}

tr:nth-child(3):hover {
  background-color: #599951;
  transform: scale(1.1);
}

tr:nth-child(4):hover {
  background-color: #6ea666;
  transform: scale(1.1);
}

tr:nth-child(5):hover {
  background-color: #83b37c;
  transform: scale(1.1);
}

tr:nth-child(odd) {
  background-color: #2b2a2a;
}

tr:nth-child(even) {
  color: var(--even);
}

tr:nth-child(1) {
  color: #ffffff;
}

td {
  height: 5rem;
  font-family: "Jost", sans-serif;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  position: relative;
}

.number {
  width: 1rem;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: left;
}

.name {
  text-align: center;
  margin-left: -1.2rem;
  font-size: 1.8rem;
}

.points {
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.points:first-child {
  width: 10rem;
}

.gold-medal {
  height: 3rem;
  margin-left: 1.5rem;
}

.silver-medal {
  height: 3rem;
  margin-left: 1.5rem;
}

.bronze-medal {
  height: 3rem;
  margin-left: 1.5rem;
}

.ribbon {
  width: 101.75%;
  height: 5.5rem;
  top: -0.5rem;
  background-color: #308025;
  position: absolute;
  left: -1rem;
  -webkit-box-shadow: 0rem 1.5rem 1.1rem -0.6rem #7a7a7d;
  box-shadow: 0rem 1.5rem 1.1rem -0.6rem #7a7a7d;
}

.ribbon::before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  bottom: -0.8rem;
  left: 0.35rem;
  transform: rotate(45deg);
  background-color: #87e55b;
  position: absolute;
  z-index: -1;
}

.ribbon::after {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  bottom: -0.8rem;
  right: 0.35rem;
  transform: rotate(45deg);
  background-color: #87e55b;
  position: absolute;
  z-index: -1;
}

#buttons {
  width: 100%;
  margin-top: 37rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.exit {
  width: 11rem;
  height: 3rem;
  font-family: "Jost", sans-serif;
  font-size: 1.3rem;
  font-weight: bolder;
  text-transform: uppercase;
  color: #7e7f86;
  border: 0;
  background-color: #fff;
  border-radius: 2rem;
  cursor: pointer;
}

.exit:hover {
  border: 0.3rem solid #5c5be5;
  color: #5c5be5;
}

.continue {
  width: 11rem;
  height: 3rem;
  font-family: "Jost", sans-serif;
  font-size: 1.3rem;
  color: #fff;
  text-transform: uppercase;
  background-color: #5c5be5;
  border: 0;
  border-bottom: 0.4rem solid #3838b8;
  border-radius: 2rem;
  cursor: pointer;
}

.continue:active {
  border-bottom: 0;
}
/* Contributor- teacher.js css ends here  */

/* Userlist css starts here  */
.user-list {
  margin-top: 10rem;
}
.userlist {
  text-align: center;
  margin: 2rem 0;
  font-size: 3.5rem;
}
.level{
  margin: 3rem;
  /* width: 90%; */
}
.user-list .accordion {
  width: 90%;
  max-width: 100rem;
  margin: 2rem auto;
  background-color: var(--white);
  backdrop-filter: blur(100px);
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  
}
.user-list .accordion-item {
  background-color: var(--white);
  color: var(--black);
  margin: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.25);
}
.user-list .accordion-item.active .accordion-item-body-content {
  max-height: 100rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
}

.user-list .accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  font-size: 2rem;
  min-height: 4rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.user-list .accordion-item-header::after {
  content: "\002B";
  font-size: 2rem;
  position: absolute;
  right: 1rem;
}
.user-list .accordion-item-header.active::after {
  content: "\2212";
}

.user-list .accordion-item.active .profile-pic-list,
.user-list .accordion-item.active h3 {
  display: none;
}

.user-list .accordion-item-body {
  justify-content: center;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}
.user-list .accordion-item-body-content {
  padding: 1rem;
  line-height: 1.5rem;
  border-top: 0.1rem solid;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
}

/* Content-1:Start */
.user-list .box-user-list {
  width: 100rem;
  height: fit-content;
  border-radius: 2rem;
  padding: 1rem;
  text-align: center;
  background: var(--white);
}

.user-list .box1 {
  margin-top: -2.5rem;
}

.user-list .content {
  margin: 1.5rem 0.2rem;
  text-align: center;
  width: 100%;
}

/* CSS for the profile image with zoom effect on hover */
.user-list .image-user-list {
  height: 15rem;
  width: 15rem;
  border-radius: 50%;
  display: block;
  margin-left: 0rem;
  margin-right: auto;
  margin-bottom: 0.5rem;
  object-fit: cover;
  /* Add transition for smooth zoom effect */
  transition: transform 0.3s ease;
  /* Set transform-origin to the center of the image */
  transform-origin: center;
  margin-top: 2rem;
}
.user-list .image {
  width: 15rem;
  height: 15rem;
  margin-left: 42%;
}
/* Apply zoom effect on hover */
.user-list .image :hover {
  transform: scale(
    1.1
  ); /* Increase the scale value to increase the zoom effect */
}

.user-list .name {
  font-family: "Roboto Mono", monospace;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  size: 10rem;
}
.user-list span{
  font-family: "Roboto Mono", monospace;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  size: 10rem;
}
.user-list .job_title {
  font-size: 1.3em;
  font-weight: bolder;
  color: gray;
  margin-top: 1rem;
}

.user-list .job_description {
  font-family: "Roboto Mono", monospace;
  text-align: center;
  font-size: 1.2em;
  color: var(--black);
  margin: 1rem 3rem 2rem;
}

.user-list .icons {
  margin: 0rem 3rem;
  font-size: 2.5em;
  display: flex;
  justify-content: space-around;
}

.user-list .icons button {
  width: fit-content;
  height: 0rem;
  border: none;
  font-size: 1em;
  color: var(--black);
}

.user-list ion-icon:hover {
  color: #58a497;
  transition: 0.5s;
}

.user-list button {
  width: 13rem;
  height: 4rem;
  border-radius: 1rem;
  font-weight: bolder;
}

.user-list .button {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 2rem 3rem 0rem;
}

/* Content-1:End */

/* Content-2:Start */
.user-list .box2 {
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.user-list .box2 img {
  width: 8rem;
}

.user-list .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0rem 0rem 1rem;
}

.user-list h5 {
  font-weight: bolder;
  font-size: 1em;
}

.user-list .row p {
  font-size: 0.8em;
}

.user-list .box2 .text {
  text-align: left;
}

.user-list .box2 .text .name {
  font-size: 1.1em;
}

.user-list .box2 .text .job_title {
  font-size: 0.6em;
  margin-bottom: 1rem;
}

.user-list .box2 .text .job_description {
  margin: 0rem;
}

.user-list .box2 .text .about {
  font-size: 0.9em;
  font-weight: bolder;
}
.user-list .profile-pic-list {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  margin-right: 1.7rem;
  transition: transform 0.3s ease;
}
.user-list .profile-pic-list::after {
  display: none;
}
/* Content-2:End */
.user-list ul {
  display: flex;
  gap: 4rem;
}
.user-list .typing {
  font-family: "Roboto Mono", monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  color: var(--black);
  font-weight: 700;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.user-list #typing::after {
  content: "|";
  position: relative;
  right: 0;
  animation: blink 0.75s step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: rgb(255, 0, 0);
  }
}

.user-list .icon-verified {
  width: calc(100% / 39);
  vertical-align: top;
  margin-top: -0.4rem;
  /* height: calc(100vh / 20); */
}

.user-list .gold {
  fill: rgb(255, 224, 66);
}

.user-list .pagination-outer {
  text-align: center;
}
.user-list .pagination {
  display: inline-flex;
  position: relative;
}
.user-list .pagination li a.page-link {
  padding: 0.5rem 1.5rem;
  margin-right: 1.5rem;
  background: #f8f8f8;
  border: 0.1rem solid #e8e8e8;
  border-radius: 0.5rem;
  box-shadow: 0 0.8rem 0.6rem -0.9rem #5b5f5f;
  font-size: 1.7rem;
  color: #686c6c;
}
.user-list .pagination li:first-child a.page-link {
  margin-right: 3rem;
}
.user-list .pagination li:last-child a.page-link {
  margin: 0 0 0 1.5rem;
}
.user-list .pagination li.active a.page-link,
.user-list .pagination li a.page-link:hover {
  background: #85b83d;
  border-color: #739d46;
  color: #fff;
}

.user-list ul li:nth-child(1) a:hover {
  color: var(--github);
  background-color: var(--leader);
}

.user-list ul li:nth-child(2) a:hover {
  background-color: #0a66c2;
}

.user-list ul li:nth-child(3) a:hover {
  background: linear-gradient(to right, #870000, #190a05);
}

.user-list ul li:nth-child(4) a:hover {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
}
.user-list ul li:nth-child(5) a:hover {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}
.user-list ul li:nth-child(6) a:hover {
  /* background-color: #000000; */
  color: var(--github);
  background-color: var(--leader);
}

/* Userlist css ends here  */
/* sidebar and userlist css ul tags  */

ul li {
  list-style-type: none;
}
ul li a {
  display: flex;
  width: 4rem;
  height: 4rem;
  color: var(--black);
  background-color: var(--icon);
  border-radius: 50%;
  transition: all 0.4s;
  align-items: center;
  justify-content: center;
}
footer ul li{
  width: 14rem !important;
}
footer ul li a{
  width: 9rem !important;
}
/* ul li a:hover {
  color: white;
  transform: translate(0rem, -0.7rem);
} */

ul li a svg {
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
}

/* sidebar and userlist css ul ends here  */
/* Update and view profile css starts here  */
.update .form-container {
  margin: 8rem 15rem;
  gap: 1rem;
  font-size: 1rem;
}
.update .form-container .form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.5rem;
  gap: 1rem;
}
.update .form-container img {
  width: 15rem;
  height: 15rem;
  margin-left: 40rem;
}

.update .form-container form .box.left-field {
  width: 48%;
  padding: 1.2rem;
}

.update .form-container form .box.right-field {
  width: 48%;
  padding: 1.2rem;
}
.form-container form .box[type="number"]::-webkit-inner-spin-button,
.form-container form .box[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.update .form-container form {
  background-color: var(--white);
  border-radius: 0rem;
  padding: 2rem;
  width: 100rem;
  flex-direction: column;
  margin: 0 auto;
}

.update .form-container form .box {
  background-color: var(--light-bg);
  width: 100%;
  margin: 0rem;
  padding: 0rem;
  position: relative;
}
.update .form-container form  input {
  background: none !important;
  border: none !important;
  box-shadow: none;
}
/* Apply padding only in non-editing mode */
.update .form-container form .box.padding {
  padding: 1.4rem;
}
.update .form-container form .box p {
  position: absolute;
  left: 1rem;
  top: -2rem;
  font-size: 1.2rem;
  color: var(--black);
  background-color: var(--white);
  padding: 0 0.5rem;
}

.update textarea {
  width: 100%;
  height: 10rem;
  border: none;
  /* border-bottom: 0.2rem solid var(--light-color); */
  padding: 1.4rem !important;
  background-color: var(--light-bg);
  margin: 1rem 0;
}

.update .selected-skills-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.update .selected-skill {
  display: flex;
  align-items: center;
  background-color: var(--main-color);
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  margin: 0.4rem;
}

.update .selected-skill span {
  margin-right: 0.8rem;
}

.update .selected-skill button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  width: 2rem;
}

.update .skills-dropdown {
  position: relative;
  z-index: 1000;
}

.update .skills-options {
  position: inherit;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  border: 0.1rem solid var(--main-color);
  border-radius: 0.4rem;
  max-height: 15rem;
  overflow-y: auto;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.update .skill-option {
  padding: 0.8rem 1.6rem;
  cursor: pointer;
}
.update .selected-roles-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.update .selected-role {
  display: flex;
  align-items: center;
  background-color: var(--main-color);
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
  margin: 0.4rem;
}

.update .selected-role span {
  margin-right: 0.8rem;
}

.update .selected-role button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}

.update .roles-dropdown {
  position: relative;
}

.update .roles-options {
  position: inherit;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  border: 0.1rem solid var(--main-color);
  border-radius: 0.4rem;
  max-height: 15rem;
  overflow-y: auto;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

.update .role-option {
  padding: 0.8rem 1.6rem;
  cursor: pointer;
}
.update .form-container .about-me {
  border: none;
  padding: 1.2rem;
  border-radius: 2rem;
  font-size: 1.8rem;
  color: var(--black);
  margin: 1rem 1rem;
  background-color: var(--light-bg);
  min-height: 10rem;
}

.viewprofile {
  width: 80%;
  margin: 0 auto;
  padding: 3rem;
  border-radius: 1rem;
  background-color: var(--leader);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.viewprofile .image {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}
.image {
  width: 100%;
  height: 15rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.button-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.button {
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: bold;
  cursor: pointer;
}

.message-button {
  background-color: var(--orange);
  color: var(--white);
}

.connect-button {
  background-color: var(--main-color);
  color: var(--white);
}

.profile-fields {
  text-align: left;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  color: var(--black);
}

.profile-field {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

/* View Profile css ends  */

.edit-icon,
.save-icon {
  font-size: 2.5rem;
  color: var(--black);
  position: absolute;
  right: 8rem;
  margin-top: -3rem;
  cursor: pointer;
}
.calendar {
  right: 3rem;
  margin-top: -0.4rem;
  font-size: 3rem;
}
.hidden-input {
  display: none;
}

.profile-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.profile-pic-container {
  cursor: pointer;
  /* Style your profile pic container, e.g., set a fixed width and height */
}
.profile-pic-label {
  display: block;
  width: 100%;
  text-align: center;
}
.profile-pic-preview {
  width: 20rem;
  height: 20rem;
  max-width: 100%;
  max-height: 100%;
  padding: 1.2rem;
}
.profile-info {
  flex-grow: 1;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: yellow;
  }
}

/* Update and view profile css ends here  */

/* Gita css starts here  */
/* Remove the align-items, align-self, and display properties from the .gita p selector */
/* Optional: To center-align the entire content on the page */

.gita {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 65rem;
  min-width: 40rem;
  z-index: 1;
}

.gita::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.gita p {
  font-size: 2.5rem;
  text-align: center;
  color: var(--gitaColor);
  z-index: 2;
  max-width: 60rem;
}

/* Gita css ends here  */
/*Roadmap css starts here */
.main-title {
  width: 100%;
  text-align: center;
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      34deg,
      rgba(155, 255, 118, 1) 0%,
      rgba(0, 194, 255, 1) 100%
    );
    color: #e5895f;
  font-size: 5rem;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}
.slider {
  margin: 8rem 0;
  height: 20rem;
  display: block;
  perspective: 100rem;
  position: relative;
  width: 100vw;
}

.slide {
  margin-left: -5rem;
  display: inline-block;
  width: 40rem;
  position: absolute;
  backface-visibility: hidden;
  color: var(--roadmap-text);
  /* backdrop-filter: blur(.2rem); */
  border: double 0.1rem transparent;
  border-radius: 2rem;
  background: var(--roadmap);
/* background: -moz-linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgb(1, 4, 5) 100%); */
background: var(--roadmap-slide);
/* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgb(2, 6, 7) 100%); */
  /* background-image: linear-gradient(#0200546b, #0000006b),
    linear-gradient(to right, #661dee73, #009dff75); */
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: var(--roadmap-box-shadow);
  padding: 0.75rem;
}

.slide-container {
  padding: 2rem 3rem;
  /* backdrop-filter: blur(2px); */
  border-radius: 2rem;
}

.slide-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.slide-container ul li {
  padding: 0;
}

.slide.active {
  z-index: 4;
}
.roadmap-slide-center {
  font-size: 3rem;
  align-items: center;
  justify-content: center;
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      34deg,
      rgb(255, 29, 146) 0%,
      rgb(186, 0, 99) 100%
    );
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  color: deeppink;
}
.slide-Title {
  font-size: 2.25rem;
  align-items: center;
  justify-content: center;
  color: var(--roadmapTitle);

}
.roadmap-slide-center-subtitle {
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  color: var(--roadmapText);
}

.slide-description ul {
  grid-gap: 1rem;
  font-size: 1.5rem;
}

/* about us student review  css code  */

@import url(//cdn.rawgit.com/rtaibah/dubai-font-cdn/master/dubai-font.css);

.testim {
  width: 100%;
  position: absolute;
  /* top: 50%; */
  -webkit-transform: translatey(-50%);
  -moz-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -o-transform: translatey(-50%);
  transform: translatey(-50%);
  padding-bottom: 20rem;
}

.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 102rem;
  padding: 4rem 2rem;
  margin: auto;
  margin-bottom: -17rem;
  /* padding-bottom: 20rem; */
}

.testim .arrow-about {
  display: block;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0.5rem;
  padding: auto;
  /* z-index: 22222222; */
}

.testim .arrow-about:before {
  cursor: pointer;
}

.testim .arrow-about:hover {
  color: #ea830e;
}

.testim .arrow-about.left {
  left: 1rem;
}

.testim .arrow-about.right {
  right: 1rem;
}

.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 6rem;
  left: 0;
  display: block;
  /* z-index: 3333; */
  height: 1.2rem;
}

.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  border: 0.1rem solid #eee;
  margin: 0 1rem;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.testim .dots .dot.active-student,
.testim .dots .dot:hover {
  background: #ea830e;
  border-color: #ea830e;
}

.testim .dots .dot.active-student {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
}

.testim .cont {
  position: relative;
  overflow: hidden;
}

.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 7rem 0;
  opacity: 0;
}

.testim .cont > div.inactive-student {
  opacity: 1;
}

.testim .cont > div.active-student {
  position: relative;
  opacity: 1;
}

.testim .cont div .img img {
  display: block;
  width: 10rem;
  height: 10rem;
  margin: auto;
  border-radius: 50%;
}

.testim .cont div h2 {
  color: #ea830e;
  font-size: 1em;
  margin: 1.5rem 0;
}

.testim .cont div p {
  font-size: 1.15em;
  color: #eee;
  width: 80%;
  margin: auto;
}

.testim .cont div.active-student .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}

.testim .cont div.active-student h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}

.testim .cont div.active-student p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}

.testim .cont div.inactive-student .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}

.testim .cont div.inactive-student h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}

.testim .cont div.inactive-student p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0rem 0rem 0rem 0rem #eee;
    box-shadow: 0rem 0rem 0rem 0rem #eee;
  }

  35% {
    -webkit-box-shadow: 0rem 0rem 1rem 0.5rem #eee;
    box-shadow: 0rem 0rem 1rem 0.5rem #eee;
  }

  70% {
    -webkit-box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
    box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
  }

  100% {
    -webkit-box-shadow: 0rem 0rem 0rem 0rem #ea830e;
    box-shadow: 0rem 0rem 0rem 0rem #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0rem 0rem 0rem 0rem #eee;
    box-shadow: 0rem 0rem 0rem 0rem #eee;
  }

  35% {
    -moz-box-shadow: 0rem 0rem 1rem 0.5rem #eee;
    box-shadow: 0rem 0rem 1rem 0.5rem #eee;
  }

  70% {
    -moz-box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
    box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
  }

  100% {
    -moz-box-shadow: 0rem 0rem 0rem 0rem #ea830e;
    box-shadow: 0rem 0rem 0rem 0rem #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0rem 0rem 0rem 0rem #eee;
    box-shadow: 0rem 0rem 0rem 0rem #eee;
  }

  35% {
    -ms-box-shadow: 0rem 0rem 1rem 0.5rem #eee;
    box-shadow: 0rem 0rem 1rem 0.5rem #eee;
  }

  70% {
    -ms-box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
    box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
  }

  100% {
    -ms-box-shadow: 0rem 0rem 0rem 0rem #ea830e;
    box-shadow: 0rem 0rem 0rem 0rem #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0rem 0rem 0rem 0rem #eee;
    box-shadow: 0rem 0rem 0rem 0rem #eee;
  }

  35% {
    -o-box-shadow: 0rem 0rem 1rem 0.5rem #eee;
    box-shadow: 0rem 0rem 1rem 0.5rem #eee;
  }

  70% {
    -o-box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
    box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
  }

  100% {
    -o-box-shadow: 0rem 0rem 0rem 0rem #ea830e;
    box-shadow: 0rem 0rem 0rem 0rem #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0rem 0rem 0rem 0rem #eee;
  }

  35% {
    box-shadow: 0rem 0rem 1rem 0.5rem #eee;
  }

  70% {
    box-shadow: 0rem 0rem 1rem 0.5rem #ea830e;
  }

  100% {
    box-shadow: 0rem 0rem 0rem 0rem #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}
.fa-chevron-left:before {
  margin-left: -50rem;
}

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");

.container-roadmap {
  margin-top: 18rem !important;
  max-width: 100%;
  height: auto;
  position: relative;
  margin: auto;
  padding-bottom: 1rem;
  margin-bottom: 50rem !important;
}

.cursor-roadmap {
  position: absolute;
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  margin: -25rem 0 0 -25rem;
  /* box-shadow: inset -2.5rem -2.5rem 5rem #0044ff5b,
    inset 2.5rem 2.5rem 5rem #000000; */
  will-change: transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: -1;
  top: 0;
  left: 0;
}

.shapes {
  position: relative;
  height: 100vh;
  width: 100vw;
  /*   overflow: hidden; */
}
.shapes .shape {
  will-change: transform;
  position: absolute;
  border-radius: 50%;
  z-index: -2;
}
.shapes .shape.shape-1 {
  background: var(--roadshapBG1);
  box-shadow: var(--roadshapBS1);
  width: 80rem;
  height: 80rem;
  margin: -33rem 0 0 -38rem;
}
.shapes .shape.shape-2 {
  background: var(--roadshapBG2);
  box-shadow: var(--roadshapBS2);
  width: 50rem;
  height: 50rem;
  margin: -18rem 0 0 -23rem;
}
.shapes .shape.shape-3 {
  background: var(--roadshapBG3);
  box-shadow: var(--roadshapBS3);
  width: 20rem;
  height: 20rem;
  margin: -3rem 0 0 -8rem;
}

.background-roadmap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background: #0711295b;
  box-shadow: inset -2.5rem -2.5rem 5rem #1527585b,
    inset 2.5rem 2.5rem 5rem #000000;
  content: "";

}

.one {
  width: 140rem;
  height: 140rem;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.8s ease;
}

.two {
  width: 110rem;
  height: 110rem;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.8s ease;
}

.three {
  width: 82rem;
  height: 82rem;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.8s ease;
}

.four {
  width: 51rem;
  height: 51rem;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.8s ease;
}

.w-50 {
  width: 50%;
  height: auto;
}

.w-25 {
  width: 25%;
  height: auto;
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner-logo {
  width: 10rem; /* Adjust the width as needed */
  height: 10rem; /* Adjust the height as needed */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo-header {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}

/* ------ UNLOCK ------ */

#unlock {
  font-family: helvetica;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: -19rem;
  width: 186px;
  height: 73px;
  text-align: center;
  opacity: 1;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
#unlock.unlock {
  opacity: 0;
  -webkit-transform: translate(-486px, -50%);
  -ms-transform: translate(-486px, -50%);
  -o-transform: translate(-486px, -50%);
  transform: translate(-486px, -50%);
}
#unlock .title {
  opacity: 0.5;
  color: #fff;
  font-size: 11px;
}
#unlock .subtitle {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    338deg,
    rgb(241 245 240) 0%,
    rgb(0 0 0 / 51%) 100%
  );
}
#unlock .content {
  width: 35rem;
  height: 10rem;
  margin: auto;
  position: relative;
  text-align: center;
  margin-left: -8.5rem;
}
#unlock .content:after {
  content: "Feeling Lazy.. ? Click Below";
  color: #fff;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 5rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    191deg,
    rgb(234 29 185) 0%,
    rgb(112 207 15 / 78%) 100%
  );
  font-size: 2rem;
  /* font-family: f-gm; */
  position: absolute;
  left: 50%;
  bottom: -10rem;
  width: 100%;
  transform: translateX(-50%);
}
#unlock .content .drag {
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  width: 10rem;
  height: 10rem;
  background-color: var(--dragBG);
  border: 1px solid #6eed9f;
  border-radius: 100px;
  position: absolute;
  z-index: 1;
  left: 0px;

  -webkit-transition: background-color 0.25s ease;
  -moz-transition: background-color 0.25s ease;
  -ms-transition: background-color 0.25s ease;
  -o-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
  touch-action: pan-y pinch-zoom;
}
#unlock .content .drag:active {
  cursor: ns-resize;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;

  background-color: rgba(255, 255, 255, 0.6);
}
#unlock .content .drag svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  -ms-transform: translate(-50%, -50%) rotate(-90deg);
  -o-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  height: 10px;
  fill: #fff;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#unlock .content .drag:active svg {
  fill: #fff;
}

#unlock .content .dot {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 1rem;
  height: 100%;
}
#unlock .content .dot:after {
  content: "";
  height: 3px;
  width: 3px;
  border-radius: 2px;
  background-color: #1bd6d6;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#unlock .content .drop {
  width: 10rem;
  height: 10rem;
  border: 1px solid var(--dropBorder);
  border-radius: 100px;
  position: absolute;
  right: 0px;
  top: 0px;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#unlock .content .drop.active {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
#unlock .content .drop.hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
}
#unlock .content .drop .wave {
  width: 19px;
  height: 19px;
  background-color:var(--dropWave);
  border-radius: 10px;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
#unlock .content .drop.active .wave {
  background-color: rgba(255, 255, 255, 0.5);
}
#unlock .content .drop.hover .wave {
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transform: translate(-50%, -50%) scale(1.7);
  -ms-transform: translate(-50%, -50%) scale(1.7);
  -o-transform: translate(-50%, -50%) scale(1.7);
  transform: translate(-50%, -50%) scale(1.7);
}

/* ------ END UNLOCK ------ */

/* HOME */
#home {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
#home.unlock {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
}
.restart {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    34deg,
    rgba(155, 255, 118, 1) 0%,
    rgba(0, 194, 255, 1) 100%
  );
  color: #e5895f;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* END HOME */
.swiper-container {
  position: relative;
  z-index: 1;
  /* margin-top: -19rem; */
  padding-bottom: 85rem;
}
.title-apple {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 3.9rem;
  margin-left: -5rem;
  width: 30rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    34deg,
    rgba(155, 255, 118, 1) 0%,
    rgba(0, 194, 255, 1) 100%
  );
  color: #e5895f;
}

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* CSS */
.button-57 {
  position: relative;
  overflow: hidden;
  border: 1px solid #18181a;
  color:#ff0000;
  display: inline-block;
  font-size: 15px;
  margin-top: 12rem;
  line-height: 15px;
  padding: 18px 18px 17px;
  text-decoration: none;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-57 span:first-child {
  position: relative;
  transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 10;
}

.button-57 span:last-child {
  color: white;
  display: block;
  position: absolute;
  bottom: 0;
  transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
  z-index: 100;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translateY(225%) translateX(-50%);
  height: 14px;
  line-height: 13px;
}

.button-57:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transform-origin: bottom center;
  transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
  transform: skewY(9.3deg) scaleY(0);
  z-index: 50;
}

.button-57:hover:after {
  transform-origin: bottom center;
  transform: skewY(9.3deg) scaleY(2);
}

.button-57:hover span:last-child {
  transform: translateX(-50%) translateY(-100%);
  opacity: 1;
  transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}

/* Footer Main  */
.footer-main{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.profile-card{
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: #ffffff00;
  padding: 30px;
  border-radius: 50%;
  box-shadow: 0 0rem 2rem -1rem #ffffff;
  transition: .6s;
  margin: 0 25px;
}
.profile-card:hover{
  border-radius: 10px;
  height: 30rem;
}
.profile-card .img{
  position: relative;
  width: 100%;
  height: 100%;
  transition: .6s;
  z-index: 99;
}
.profile-card:hover .img{
  transform: translateY(-60px);
}
.footer-img img{
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: .6s;
}
.profile-card:hover img{
  border-radius: 10px;
}
.caption{
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: .6s;
}
.profile-card:hover .caption{
  opacity: 1;
  margin-top: 10rem;
}
.caption h3{
  font-size: 21px;
  font-family: Helvetica, sans-serif;
  color: var(--black);
}
.caption p{
  font-size: 1.5rem;
  color: var(--black);
  font-family: Helvetica,sans-serif;
  margin: 2px 0 9px 0;
  background: #BAE4FF;
    background: var(--black);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.caption .social-links{
  margin-top: -0.5rem;
  margin-left: 1.5rem;
}
.caption .social-links a{
  color: var(--black);
  margin-right: 15px;
  font-size: 21px;
  transition: .6s;
}
.social-links a:hover{
  background: transparent !important;
  color: black;
}
form .custom-toast{
  margin-top:10rem !important;
}
.custom-toast{
  z-index: 1000000;
  margin-top:0rem !important;
  font-size:1.5rem;
  background-color: var(--black);
}
.Toastify__toast-container {
  z-index: 1000000000;
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  /* position: fxed; */
  margin-top:8rem !important;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.footer-widget{
  margin-left: -44rem;
  width: 100%;
}


.inline-btnd{
  visibility: hidden;
}


.title-apple-coming-soon {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 8rem;
  width: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient( 34deg, rgba(155, 255, 118, 1) 0%, rgba(0, 194, 255, 1) 100% );
  color: #e5895f;
  margin-top: 10rem;
  text-align: center;
  margin-bottom: 10rem;
}
.playlist-details{
  margin-top: 10rem !important;
}
/* Responsiveness:Start */
@media only screen and (max-width: 1440px) {
  .container-roadmap{
    margin-top: 15rem !important;
  }

  
  .playlist{
    margin-left: 10rem;
  }
  .header .flex .logo-trans {
    margin-left: -3rem;
    margin-right: -5rem;
  }
  .container {
    max-width: 120rem;
  }
  .roadmap-content {
    padding: 4rem 0rem;
  }
  .about {
    margin-left: 8rem;
    margin-top: 2rem;
  }
  .reviews{
    margin-left: 8rem;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 25rem;
    margin-bottom: 5rem;
  }
  .form-container form{
    width: 60rem;
  }
  
}

@media only screen and (max-width: 1080px) {
  /* .comments .add-comment{
    margin-left: 10rem;
    width: 84.3rem;
  } */
  .logo-header {
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    margin-right: 0.7rem;
    margin-bottom: 1rem;
  }
  
  .welcome-container{
    margin-top: 6.5rem !important;
  }
  .sidebar .menu-links{
    margin-top: -6rem;
  }
  .container-roadmap{
    margin-top: 20rem !important;
  }
  .container-roadmap .main-title{
    margin-left: 4rem;
  }
  .update .form-container{
    margin-left: 12rem;
    margin-right: 2rem;
  }
  .header .flex .logo-trans {
    margin-left: -1.5rem;
    margin-right: -7rem;
  }
  .user-list{
    margin-left: 7rem;
  }
  .form-container form{
    width: 50rem;
  }
  .container {
    max-width: 120rem;
  }
  .roadmap-content {
    padding: 0rem 0rem;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13rem;
    margin-bottom: 5rem;
  }
  .footer-main{
    margin-left: 3rem;
  }
  .footer-widget{
    margin-left: -16rem !important;
  }
  .watch-video .video-container{
    /* margin-left: 7rem; */
  }
  .comments{
    margin-left: 7rem;
  }
  .courses{
    /* margin-left: 2rem !important; */
  }
}
@media (max-width: 1200px) {
  body {
    padding-left: 0;
  }
  .about{
    margin-top: 10rem;
  }
  .about .box-container{
    gap: 4rem;
    margin-left: 30%;
    width: 40%;
    margin-bottom: 2rem;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13rem;
    margin-bottom: 5rem;
  }
  .courses{
    /* margin-left: 1rem; */
  }
  .courses ul#tabs{
    margin-left: 2rem;
  }
  .courses .heading{
    margin-left: 2rem;
  }
  .sidebar .toggle-icon.close {
    margin-top: 20rem;
  }
}
@media (min-width: 870px) and (max-width: 991px) {
  html {
    font-size: 55%;
  }
  .logo-header {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 0.7rem;
    margin-bottom: 1rem;
  }
  


  .welcome-container{
    margin-top: -5rem !important;
  }
  .update .form-container{
    margin-left: 12rem;
    margin-right: 2rem;
  }
  .update .form-container img{
    margin-left: 29rem;
  }
  .header .flex .logo-trans {
    margin-left: -3rem;
    margin-right: -5rem;
  }
  .form-container form{
    width: 50rem;
  }
  .sidebar{
    margin-top: 10rem;
    height: 65rem;
  }
  .sidebar .toggle-icon.close{
    margin-top: 28rem;
  }
  .event-sidebar-tab {
    margin-top: 50rem;
  }
  .heading{
    margin-left: 5rem;
  }
  .home-grid .box-container .cheatsheet-box {
    margin-left: -20rem;
    width:80rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 3rem;
width: 40rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: -9rem;
width: 40rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -20rem;
width: 80rem;
/* height: 20rem; */
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 5rem;
  }
  
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13rem;
    margin-bottom: 5rem;
    width: 80rem;
  }
  .watch-video .video-container{
    /* margin-left: 9rem; */
  }
  .comments{
    margin-left: 0rem;
  }
  .about{
    margin-top: 10rem !important;
  }
}
@media (min-width: 769px) and (max-width: 869px) {
  .logo-header {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 0.7rem;
    margin-bottom: 0.2rem;
  }
  
  .welcome-container{
    margin-top: -2rem !important;
  }
  .welcome-container h2{
    font-size: 3rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 3rem;
  }
  .container-roadmap{
    margin-top: 22rem !important;
  }
  .form-container form{
    width: 50rem;
    margin-left: 5rem !important;
  }
  .update .form-container{
    margin-left: 5rem;
    margin-right: 2rem;
  }
  .update .form-container img{
    margin-left: 22rem;
  }
.edit-icon, .save-icon {
    font-size: 3rem;
    color: var(--black);
    position: absolute;
    right: 3rem;
    margin-top: -3rem;
    cursor: pointer;
}
.arrow{
  width: 3.2rem;
  height: 1rem;
  margin-left: 8rem;
  margin-bottom: 1rem;
}
 
  .comments .add-comment{
    /* margin-left: 0rem; */
  }
  .about .box-container{
    margin-left: 20%;
    width: 60%;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13rem;
    margin-bottom: 5rem;
    width: 55rem;
  }
  .form-container form {
    background-color: var(--white);
    border-radius: 4rem;
    padding: 2rem;
    width: 45rem;
    margin-left: 0rem;
  }
  #search-btn {
    display: inline-block;
  }

  .header .flex .search-form {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--white);
    border-radius: 0;
    width: 100%;
    padding: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.2s linear;
  }

  .header .flex .search-form.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .event-sidebar-tab {
    margin-top: 45rem;
  }
  .heading{
    margin-left: 5rem;
  }
  .home-grid .box-container .cheatsheet-box {
    margin-left: -12rem;
    width: 60rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 5rem;
width: 30rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: 2rem;
width: 30rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -15rem;
width: 65rem;
/* height: 20rem; */
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2.5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 3rem;
    margin-left: 3rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 3rem;
  }
  .roadmap-box .fa-css3{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 3rem;
  }
  .roadmap-box .fa-js{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 3rem;
  }
  .roadmap-box .fa-code{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 3rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 3rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 3rem;
    margin-left: 3rem;
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 3rem;
  }
  .roadmap-box .fa-react{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 3rem;
  }
  .roadmap-box .fa-node{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 3rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 3rem;
    margin-left: 3rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 3rem;
  }
  .roadmap-box .fa-java{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 3rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 3rem;
  }
  
  .footer-section {

    width:fit-content
  }
  .footer-section .container{
    width: 100%;
   
  }
  .footer-cta{
    margin-left: 10rem;
  }
  .footer-main{
    margin-left: 3rem;
  }
  .footer-widget{
    margin-left: -16rem !important;
  }
  .watch-video .video-container{
    margin-left: 4rem;
  }
  .comments{
    margin-left: 4rem;
  }
 
  .comments .add-comment{
    /* margin-left: 6rem !important; */
    width: auto;
  }
}
@media (min-width: 680px) and (max-width:769px){
  
  .welcome-center-container {
    /* background: rgba(20, 20, 20, 0.103); */
    /* text-align: center; */
    width: auto;
    border-radius: 0.5rem;
    /* justify-content: center; */
    padding: 0rem 0rem;
    display: flex;
    gap: 2rem;
    height: 6rem;
    margin-top: -0.5rem;
    /* margin-left: -1rem; */
  }
.logo-header {
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}
  .comments .add-comment{
    /* margin-left: 6rem !important; */
    width: auto;
  }
  .container-roadmap{
    margin-top: 25rem !important;
  }
  .welcome-container{
    margin-top: 0rem !important;
    margin-left: 2rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 2.8rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 2.8rem;
  }
  .about .box-container{
    margin-left: 25%;
    width: 55%;
  } 
   .update .form-container{
    margin-left: 5rem;
    margin-right: 2rem;
  }
  .update .form-container img{
    margin-left: 15rem;
  }
  .arrow{
    width: 3.2rem;
    height: 1rem;
    margin-left: 5rem;
    margin-bottom: 1rem;
  }

.edit-icon, .save-icon {
    font-size: 2.5rem;
    color: var(--black);
    position: absolute;
    right: 3rem;
    margin-top: -3rem;
    cursor: pointer;
}
  .main-title{
    font-size: 3rem;
    margin-left: 3rem;
  }
  .event-sidebar-tab {
    margin-top: 40rem;
  }
  .slide.active {
    z-index: 4;
    position: absolute;
    top: 8%;
    left: 3%;
    transform: translate(-50%, -50%);
  }
  
  /* Adjust the width of the slider container */

 
  .comments .add-comment{
    /* margin-left: 0rem; */
  }
  .form-container form{
    width: 45rem;
    margin-left: 5rem !important;
  }
  .watch-video .video-container{
    margin-left: 3rem;
  }
  .comments{
    margin-left: 3rem;
  }
  #search-btn {
    display: inline-block;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13rem;
    margin-bottom: 5rem;
    width: 50rem;
  }
  .header .flex .search-form {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--white);
    border-radius: 0;
    width: 100%;
    padding: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.2s linear;
  }

  .header .flex .search-form.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .home-grid .heading{
    margin-left: 2rem;
  } 
   .home-grid .box-container .cheatsheet-box {
    margin-left: -12rem;
    width: 60rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 5rem;
width: 30rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: 2rem;
width: 30rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -15rem;
width: 65rem;
/* height: 20rem; */
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2.5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 3rem;
    margin-left: 3rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 3rem;
  }
  .roadmap-box .fa-css3{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 3rem;
  }
  .roadmap-box .fa-js{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 3rem;
  }
  .roadmap-box .fa-code{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 3rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 3rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 3rem;
    margin-left: 3rem;
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 3rem;
  }
  .roadmap-box .fa-react{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 3rem;
  }
  .roadmap-box .fa-node{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 3rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 3rem;
    margin-left: 3rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 3rem;
  }
  .roadmap-box .fa-java{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 3rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 3rem;
    margin-left: 9rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 3rem;
  }
  
  .footer-section {

    width:fit-content
  }
  .footer-section .container{
    width: 100%;
 
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: 3rem;
    gap: 4rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: -14rem !important;
  }
  
}
@media (min-width:580px) and (max-width: 679px) {
  html{
    font-size: 55%;
  }
  .welcome-center-container {
    /* background: rgba(20, 20, 20, 0.103); */
    /* text-align: center; */
    width: auto;
    border-radius: 0.5rem;
    /* justify-content: center; */
    padding: 0rem 0rem;
    display: flex;
    gap: 2rem;
    height: 6rem;
    margin-top: -0.5rem;
    /* margin-left: 0rem; */
  }
.logo-header {
  width: 3.7rem;
  height: 3.7rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}
 
  .comments .add-comment{
    /* margin-left: 6rem !important; */
    width: auto;
  }
  .welcome-container{
    margin-top: -5rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 2.8rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 2.8rem;
  }
  .container-roadmap{
    margin-top: 20rem !important;
  }
  .update .form-container{
    margin-left: 5rem;
    margin-right: 2rem;
  }
  .update .form-container img{
    margin-left: 16rem;
  }
  .arrow{
    width: 3.2rem;
    height: 1rem;
    margin-left: 6,5rem;
    margin-bottom: 1rem;
  }
  .edit-icon, .save-icon {
    font-size: 2rem;
    color: var(--black);
    position: absolute;
    right: 3rem;
    margin-top: -3rem;
    cursor: pointer;
}
  .slide.active {
    z-index: 4;
    position: absolute;
    top: 8%;
    left: 3%;
    transform: translate(-50%, -50%);
  }

  .comments .add-comment{
    /* margin-left: 0rem; */
  }
  #search-btn {
    display: inline-block;
  }
  .form-container form{
    width: 45rem;
    margin-left: 5rem !important;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13rem;
    margin-bottom: 5rem;
    width: 50rem;
  }
  .gita{
    margin-left: 10rem;
  }
  .about .box-container{
    margin-left: 20%;
    width: 60%;
  }
.heading{
margin-left: 3rem;
}
.watch-video .video-container{
  margin-left: 2rem;
}
.comments{
  margin-left: 2rem;
}
  .header .flex .search-form {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--white);
    border-radius: 0;
    width: 100%;
    padding: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.2s linear;
  }

  .header .flex .search-form.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .sidebar.close{
    width: 9rem;
  }
  .sidebar.open{
    font-size: 10rem;
    width: 100%;
  }
  .sidebar.open ul {
    margin-left: 18rem;
  }
  .sidebar.open header .image {
    margin-left: 20rem;
  }
  .sidebar.open .name{
    margin-left: -25rem;
  }
  .sidebar header .toggle-icon.open{
    right: -0.1rem;
  }
  .sidebar.open .logo-text{
    margin-left: 20rem;
  }
  .home-grid .box-container .flex a {
    padding: 3rem 4rem
  }
  .fa-html5{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-html5{
    font-size: 4rem;
  }
  .fa-css3{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-css3{
    font-size: 4rem;
  }
  .fa-js{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-js{
    font-size: 4rem;
  }
  
  .fa-bootstrap{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-bootstrap{
    font-size: 4rem;
  }
  .fa-react{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-react{
    font-size: 4rem;
  }
  .fa-file-code{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-file-code{
    font-size: 4rem; 
  }
  .fa-database{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-database{
    font-size: 4rem;
  }
  .fa-python{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-python{
    font-size: 4rem;
  }
  .fa-globe{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-globe{
    font-size: 4rem;
  }
  
  .home-grid .box-container .cheatsheet-box {
    margin-left: -5rem;
    width: 50rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: -2rem;
width: 40rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: -2rem;
width: 40rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -4rem;
width: 45rem;
height: 70rem;
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2.5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 2.5rem;
    margin-left: 1.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-css3{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-js{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-code{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 2.5rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 2.5rem;
    margin-left: 1.5rem;
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-react{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-node{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 2.5rem;
    margin-left: 1.5rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-java{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 2.5rem;
  }
  
  .footer-section {

    width:fit-content
  }
  .footer-section .container{
    width: 100%;
   
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: 3rem;
    gap: 4rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: 4rem !important;
  }
  .copyright-text{
    margin-left: -55rem;
  }
  .footer-content{
    margin-left: 7rem;
    width: 50rem;
  }
}
@media (min-width:541px) and (max-width: 579px) {
  html{
    font-size: 55%;
  }
  .welcome-center-container {
    /* background: rgba(20, 20, 20, 0.103); */
    /* text-align: center; */
    width: auto;
    border-radius: 0.5rem;
    /* justify-content: center; */
    padding: 0rem 0rem;
    display: flex;
    gap: 2rem;
    height: 6rem;
    margin-top: -0.5rem;
    /* margin-left: -10rem; */
  }
.logo-header {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}
  .welcome-container{
    margin-top: -5rem !important;
    margin-left: 2rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 2.8rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 2.8rem;
  }
  .container-roadmap{
    margin-top: 30rem !important;
  }
  .update .form-container{
    margin-left: 5rem;
    margin-right: 2rem;
  }
  .update .form-container img{
    margin-left: 15rem;
  }
  .watch-video .video-container{
    margin-left: 1rem;
  }
  .comments{
    margin-left: 1rem;
  }
  .arrow{
    width: 3.2rem;
    height: 1rem;
    margin-left: 7rem;
    margin-bottom: 1rem;
  }
  .main-title{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
 
  .comments .add-comment{
    /* margin-left: 0rem; */
  }
  
  #search-btn {
    display: inline-block;
  }
  .form-container form{
    width: 45rem;
    /* margin-left: 5rem !important; */
  }
  .about .box-container{
    margin-left: 20%;
    width: 60%;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 12rem;
    margin-bottom: 5rem;
    width: 50rem;
  }
  .reviews{
    margin-left: 10rem;
  }
.heading{
margin-left: 3rem;
}
.courses ul#tabs{
  width: 65rem;
}
.courses ul#tabs li{
  width: 10rem;
}
  .header .flex .search-form {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--white);
    border-radius: 0;
    width: 100%;
    padding: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.2s linear;
  }

  .header .flex .search-form.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .sidebar.close{
    width: 9rem;
  }
  .sidebar.open{
    font-size: 10rem;
    width: 100%;
  }
  .sidebar.open ul {
    margin-left: 18rem;
  }
  .sidebar.open header .image {
    margin-left: 20rem;
  }
  .sidebar.open .name{
    margin-left: -25rem;
  }
  .sidebar header .toggle-icon.open{
    right: -0.1rem;
  }
  .sidebar.open .logo-text{
    margin-left: 20rem;
  }
  .home-grid .box-container .flex a {
    padding: 3rem 4rem
  }
  .fa-html5{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-html5{
    font-size: 4rem;
  }
  .fa-css3{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-css3{
    font-size: 4rem;
  }
  .fa-js{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-js{
    font-size: 4rem;
  }
  
  .fa-bootstrap{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-bootstrap{
    font-size: 4rem;
  }
  .fa-react{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-react{
    font-size: 4rem;
  }
  .fa-file-code{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-file-code{
    font-size: 4rem; 
  }
  .fa-database{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-database{
    font-size: 4rem;
  }
  .fa-python{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-python{
    font-size: 4rem;
  }
  .fa-globe{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-globe{
    font-size: 4rem;
  }
  
  .home-grid .box-container .cheatsheet-box {
    margin-left: -2rem;
    width: 40rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 2rem;
width: 30rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: 2rem;
width: 30rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -4rem;
width: 45rem;
height: 70rem;
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2.5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 2.5rem;
    margin-left: 1.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-css3{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-js{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-code{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 2.5rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 2.5rem;
    margin-left: 1.5rem;
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-react{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-node{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 2.5rem;
    margin-left: 1.5rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-java{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 2.5rem;
  }
  
  .footer-section {
   
    width:fit-content
  }
  .footer-section .container{
    width: 100%;
   
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: 3rem;
    gap: 4rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: 0rem !important;
  }
  .copyright-text{
    margin-left: -62rem;
  }
  .footer-content{
    margin-left: 7rem;
    width: 50rem;
  }
}

@media (min-width: 481px) and (max-width:540px) {
  html{
    font-size: 40%;
  }
  .welcome-center-container {
    /* background: rgba(20, 20, 20, 0.103); */
    /* text-align: center; */
    width: auto;
    border-radius: 0.5rem;
    /* justify-content: center; */
    padding: 0rem 0rem;
    display: flex;
    gap: 2rem;
    height: 6rem;
    margin-top: -0.5rem;
    /* margin-left: -8rem; */
  }
  .logo-header {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}

  .comments .add-comment{
    /* margin-left: 6rem !important; */
    width: auto;
  }
  .reviews .add-comment{
    margin-left: 0rem !important;
  }
  .welcome-container{
    margin-top: 0rem !important;
    margin-left: 0rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 2.8rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 2.8rem;
  }
  .watch-video .video-container{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .comments{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .container-roadmap{
    margin-top: 30rem !important;
  }
  .update .form-container{
    margin-left: 6rem;
    margin-right: 2rem;
  }
  .update .form-container img{
    margin-left: 22rem;
  }
  .arrow{
    width: 3.2rem;
    height: 1rem;
    margin-left: 10rem;
    margin-bottom: 1rem;
  }
  .main-title{
    font-size: 3.5rem;
    margin-left: 5rem;
    margin-top: 5rem;
  }
  .slide.active {
    z-index: 4;
    position: absolute;
    top: 8%;
    left: 10%;
    transform: translate(-50%, -50%);
  }

  .comments .add-comment{
    /* margin-left: 0rem; */
  }
  .reviews .add-comment{
    margin-left: 0rem;
  }
  .form-container form{
    width: 45rem;
    margin-left: 1rem !important;
  }
  .about .box-container{
    margin-left: 20%;
    width: 60%;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13.5rem;
    margin-bottom: 5rem;
    width: 50rem;
  }
  .courses ul#tabs{
    margin-top: 2rem;
    margin-bottom: 2rem;
    
  }
  .courses ul#tabs li{
    width: 8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;


  }
  .heading{
    margin-left: 4rem;
  }
  .sidebar.close{
    width: 9rem;
  }
  .sidebar .toggle-icon.close{
    margin-top: 30rem;
  }
  .sidebar.open{
    font-size: 10rem;
    width: 100%;
  }
  .sidebar.open ul {
    margin-left: 28rem;
  }
  .sidebar.open header .image {
    margin-left: 30rem;
  }
  .sidebar.open .name{
    margin-left: -40rem;
  }
  .sidebar header .toggle-icon.open{
    right: -0.1rem;
  }
  .sidebar.open .logo-text{
    margin-left: 32rem;
  }
  .home-grid .box-container .flex a {
    padding: 3rem 4rem
  }
  .fa-html5{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-html5{
    font-size: 4rem;
  }
  .fa-css3{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-css3{
    font-size: 4rem;
  }
  .fa-js{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-js{
    font-size: 4rem;
  }
  
  .fa-bootstrap{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-bootstrap{
    font-size: 4rem;
  }
  .fa-react{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-react{
    font-size: 4rem;
  }
  .fa-file-code{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-file-code{
    font-size: 4rem; 
  }
  .fa-database{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-database{
    font-size: 4rem;
  }
  .fa-python{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-python{
    font-size: 4rem;
  }
  .fa-globe{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-globe{
    font-size: 4rem;
  }
  
  .home-grid .box-container .cheatsheet-box {
    margin-left: -10rem;
    width: 55rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 2rem;
width: 30rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: 2rem;
width: 30rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -4rem;
width: 50rem;
height: 70rem;
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2.5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 2.5rem;
    margin-left: 2.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-css3{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-js{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-code{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 2.5rem;
    margin-left: 2.5rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 2.5rem;
    
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-react{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-node{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 2.5rem;
    margin-left: 2.5rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-java{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 2.5rem;
  }
  
  .footer-section {
  
    width:fit-content
  }
  .user-list .box {
    width: 100vw;
    border-radius: 0rem;
  }
  .user-list .button {
    display: flex;
    flex-direction: column;
  }
  .user-list .button button {
    width: 25rem;
  }

  .user-list .content2 {
    padding: 0rem 2rem;
  }
  .user-list .content2 img {
    width: 6rem;
    height: 6rem;
  }
  .user-list .pagination {
    display: block;
    overflow: auto;
  }
  .user-list .pagination:before {
    display: none;
  }
  .user-list .pagination li {
    display: inline-block;
  }
  .footer-section {
   
    width:fit-content
  }
  .footer-section .container{
    width: 100%;
  
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: 6rem;
    gap: 4rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: 4rem !important;
  }
  .copyright-text{
    margin-left: -55rem;
  }
  .footer-content{
    margin-left: 7rem;
    width: 50rem;
  }
}
@media (min-width: 391px) and (max-width:480px) {
  html{
    font-size: 40%;
  }
  .welcome-center-container {
    /* background: rgba(20, 20, 20, 0.103); */
    /* text-align: center; */
    width: auto;
    border-radius: 0.5rem;
    /* justify-content: center; */
    padding: 0rem 0rem;
    display: flex;
    gap: 2rem;
    height: 6rem;
    margin-top: -0.5rem;
    /* margin-left: -12rem; */
  }
  .logo-header {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}
  .welcome-container{
    margin-top: -5rem !important;
    margin-left: 0rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 2.5rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 2.5rem;
  }
  .title-apple{
    margin-left: 0rem !important;
  }
  #unlock .content{
    margin-left: -3rem !important;
  }
  .watch-video .video-container{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .comments{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .container-roadmap{
    margin-top: 40rem !important;
  }
  .sidebar .toggle-icon.close{
    margin-top: 30rem !important;
  }
  .event-sidebar-tab{
    margin-top: 30rem;
  }
  .update .form-container{
    margin-left: 12rem;
    width: 53rem;
  }
  .update .form-container img{
    margin-left: 15rem;
  }   
  .arrow{
    width: 3.2rem;
    height: 1rem;
    margin-left: 10rem;
    margin-bottom: 1rem;
  }
  .edit-icon, .save-icon {
    font-size: 2rem;
    color: var(--black);
    position: absolute;
    right: 3rem;
    margin-top: -3rem;
    cursor: pointer;
}
.user-list .image{
  margin-left: 35%;
}
  .main-title{
    font-size: 2.5rem;
    margin-left: 5rem;
    margin-top: 5rem;
  }
  .buttongpt{
    padding-bottom: 0.2rem;
    margin-left: -30rem;
    width: 25rem;
    margin-top: -1rem;
  }
  .welcome-text{
    font-size: 2.5rem;
    letter-spacing: 1rem;
  }
  .header .flex .icons{
    /* margin-left: -22rem; */
  }
  .slide{
    width: 28rem;
    left: 32%;
    transform: translateX(-50%);
  }
  .slide.active {
    z-index: 4;
    position: absolute;
    top: 8%;
    left: 30%;
    transform: translate(-50%, -50%);
  }
  .slider {
    margin: 8rem 0;
    height: 20rem;
    display: grid; /* Use CSS Grid for layout */
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr)); /* Divide the slides equally */
    gap: 2rem !important; /* Adjust the gap between slides */
    perspective: 100rem;
    position: relative;
    width: 100vw;
  }
 
  .comments .add-comment{
    /* margin-left: 0rem; */
  }
  
  .gita{
    margin-left: 2rem;
  }
  .playlist{
    margin-left: 8rem;
  }
  .playlist-details{
    width: auto;
    /* margin-left: 8rem; */
  }
  .playlist-videos{
    width: auto;
    /* margin-left: 8rem; */
  }
  .form-container form{
    width: 40rem;
    margin-left: -6rem !important;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 13.5rem;
    margin-bottom: 5rem;
    width: 40rem;
  }
  .courses ul#tabs{
    margin-top: 2rem;
    margin-left: 8rem;
    margin-right: 8rem;
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
  }
  .courses ul#tabs li{
    width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
  }
  .courses .box{
    min-width: 38rem;
    max-width: 38rem;
  }
  .courses .inline-btn{
    margin-left: 6rem;
  }
  .about .box-container{
    margin-left: 20%;
    width: 60%;
  }
  .form-container{
    margin-left: 5rem;
    width: 60rem;
    align-items: center;
  }
  .heading{
    margin-left: 4rem;
  }
  .sidebar.close{
    width: 9rem;
  }
  .sidebar.close ul{
    margin-top: 5rem;
    gap: 7rem;
    margin-left:2rem;
  }
  .sidebar.close header .image{
    margin-top: 7rem;
  }
  .sidebar.open{
    font-size: 10rem;
    width: 100%;
  }
  .sidebar.open ul {
    margin-left: 22rem;
    margin-top: 10rem;
    gap: 5rem;
  }
  .sidebar.open header .image {
    margin-top: 7rem;
    margin-left: 27rem;
  }
  .sidebar.open .name{
    margin-top: 1rem;
    margin-left: -20rem;
    font-size: 2rem;
  }
  .sidebar.open .profession{
    margin-top: 2rem;
    margin-left: 2rem;
    font-size: 1.5rem;
  }
  .sidebar header .toggle-icon.open{
    right: -0.5rem;
    margin-top: 25rem !important;
  }
  .sidebar .toggle-icon.close {
    margin-top: 45rem !important;
  }
  .sidebar.open .logo-text{
    margin-top: 2rem;
    margin-left: 27rem;
  }
  .home-grid .box-container .flex a {
    padding: 3rem 4rem
  }
  .fa-html5{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-html5{
    font-size: 4rem;
  }
  .fa-css3{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-css3{
    font-size: 4rem;
  }
  .fa-js{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-js{
    font-size: 4rem;
  }

  .comments .add-comment{
    /* margin-left: 6rem; */
    width: auto;
  }
  .reviews .add-comment{
    margin-left: 0rem;
  }
  .fa-bootstrap{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-bootstrap{
    font-size: 4rem;
  }
  .fa-react{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-react{
    font-size: 4rem;
  }
  .fa-file-code{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-file-code{
    font-size: 4rem; 
  }
  .fa-database{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-database{
    font-size: 4rem;
  }
  .fa-python{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-python{
    font-size: 4rem;
  }
  .fa-globe{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-globe{
    font-size: 4rem;
  }
  
  .home-grid .box-container .cheatsheet-box {
    margin-left: -5rem;
    width: 45rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: -2rem;
width: 40rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: -2rem;
width: 40rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: -5rem;
width: 45rem;
height: 70rem;
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 2.3rem;
    margin-left: 2.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-css3{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-js{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-code{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 2rem;
    margin-left: 2.5rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 2.3rem;
    
  }
  .roadmap-box .express{
    font-size: 1rem;
    margin-top: -1.5rem;
  }
  .roadmap-box .fa-circle{
    font-size: 2.3rem;
    margin-left: 3rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-react{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-node{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 2.3rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 2.3rem;
    margin-left: 2.5rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 2.3rem;
  }
  .roadmap-box .fa-java{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 2.3rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 2.3rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 2.3rem;
  }
  
  .footer-section {
   
    width:fit-content
  }
  .user-list .box {
    width: 100vw;
    border-radius: 0rem;
  }
  .user-list .button {
    display: flex;
    flex-direction: column;
  }
  .user-list .button button {
    width: 25rem;
  }

  .user-list .content2 {
    padding: 0rem 2rem;
  }
  .user-list .content2 img {
    width: 6rem;
    height: 6rem;
  }
  .user-list .pagination {
    display: block;
    overflow: auto;
  }
  .user-list .pagination:before {
    display: none;
  }
  .user-list .pagination li {
    display: inline-block;
  }
  .footer-section {
 
    width:fit-content
  }
  .footer-section .container{
    width: 100%;
  
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
    margin-left: 10rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: 0rem;
    gap: 4rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: 0rem !important;
  }
  .copyright-text{
    margin-left: -63rem;
  }
  .footer-content{
    margin-left: 7rem;
    width: 50rem;
  }
  .profile-card:hover{
    border-radius: 10px;
    height: 40rem !important;
  }
}
@media (max-width: 391px) {
  /* html {
    font-size: 50%;
  } */
  html{
    font-size: 40%;
  }
  .welcome-center-container {
    /* background: rgba(20, 20, 20, 0.103); */
    /* text-align: center; */
    width: auto;
    border-radius: 0.5rem;
    /* justify-content: center; */
    padding: 0rem 0rem;
    display: flex;
    gap: 2rem;
    height: 6rem;
    margin-top: -0.5rem;
    /* margin-left: -10rem; */
  }
  .logo-header {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  margin-right: 0rem;
  margin-bottom: 0.2rem;
}
  .container-roadmap{
    margin-top: 45rem !important;
  }
  .welcome-container{
    margin-top: 0rem !important;
    margin-left: 0rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 2.5rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 2.5rem;
  }
  
  .profile-card:hover{
    border-radius: 10px;
    height: 40rem !important;
  }
  .profile-card:hover .img{
    transform: translateY(-30px);
  }
  .title-apple{
    margin-left: 0rem !important;
  }
  #unlock .content{
    margin-left: -3rem !important;
  }
  .watch-video .video-container{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .comments{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sidebar .menu-links{
    margin-top: -6rem;
  }
  .update .form-container{
    margin-left: 1rem;
    width: 60rem;
  }
  .update .form-container img{
    margin-left: 12rem;
  }   
  .arrow{
    width: 3.2rem;
    height: 1rem;
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
  .edit-icon, .save-icon {
    font-size: 2rem;
    color: var(--black);
    position: absolute;
    right: 3rem;
    margin-top: -3rem;
    cursor: pointer;
}
  .main-title{
    font-size: 2.5rem;
    margin-left: 5rem;
    margin-top: 5rem;
  }
  .event-sidebar-tab {
    margin-top: 45rem;
  }
  .buttongpt{
    padding-bottom: 0.2rem;
    margin-left: -30rem;
    width: 25rem;
    margin-top: -1rem;
  }
  .welcome-text{
    font-size: 2.5rem;
    letter-spacing: 1rem;
  }
  .header .flex .icons{
    /* margin-left: -22rem; */
  }
  .slide{
    width: 28rem;
    left: 33%;
    transform: translateX(-50%);
  }
  .slide.active {
    z-index: 4;
    position: absolute;
    top: 8%;
    left: 33%;
    transform: translate(-50%, -50%);
  }
  .slider {
    margin: 8rem 0;
    height: 20rem;
    display: grid; /* Use CSS Grid for layout */
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr)); /* Divide the slides equally */
    gap: 2rem !important; /* Adjust the gap between slides */
    perspective: 100rem;
    position: relative;
    width: 100vw;
  }

  .comments .add-comment{
    /* margin-left: 6rem; */
    width: auto;
  }
  .reviews .add-comment{
    margin-left: 0rem;
  }
  .playlist{
    margin-left: 5rem;
  }
  .playlist-details{
    width: auto;
    /* margin-left: 8rem; */
  }
  .playlist-videos{
    width: auto;
    /* margin-left: 8rem; */
  }
  .user-list{
    margin-left: 10rem;
    margin-right: 5rem;
  }
  .user-list ul{
    gap: 2rem;
  }
  .user-list .image{
    margin-left: 31%;
  }
 .user-list .userlist-searchbar{
  margin-left: 3rem;
  width: 40rem;
 }
  .form-container form{
    width: 55rem;
    margin-left: 6rem !important;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 10rem;
    margin-bottom: 5rem;
    width: 40rem;
  }
  .courses ul#tabs{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50rem;
    gap: 2rem;
  }
  .courses ul#tabs li{
    width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .courses .box-container{
    margin-left: 3rem;
    gap: 5rem;
  }
  .courses .box{
    min-width: 35rem;
    max-width: 35rem;
  }
  .courses .inline-btn{
    margin-left: 4rem;
  }
  .about{
    margin-top: 10rem;
  }
  .about .box-container{
    margin-left: 10%;
    width: 80%;
  }
  .reviews{
    margin-left: 8rem;
  }
  .gita{
    margin-left: 5rem;
  }
  
  .heading{
    margin-left: 4rem;
  }
  
  .sidebar.close{
    width: 9rem;
  }
  .sidebar.close ul{
    margin-top: 5rem;
    gap: 7rem;
    margin-left: 2rem;
  }
  .sidebar.close header .image{
    margin-top: 7rem;
  }
  .sidebar.open{
    font-size: 10rem;
    width: 100%;
  }
  .sidebar.open ul {
    margin-left: 22rem;
    margin-top: 10rem;
    gap: 4rem;
  }
  .sidebar.open header .image {
    margin-top: 7rem;
    margin-left: 27rem;
  }
  .sidebar.open .name{
    margin-top: 1rem;
    margin-left: -20rem;
    font-size: 2rem;
  }
  .sidebar.open .profession{
    margin-top: 2rem;
    margin-left: 2rem;
    font-size: 1.5rem;
  }
  .sidebar header .toggle-icon.open{
    right: -0.5rem;
    margin-top: 20rem !important;
  }
  .sidebar .toggle-icon.close {
    margin-top: 35rem;
  }
  .sidebar.open .logo-text{
    margin-top: 2rem;
    margin-left: 27rem;
  }
  .home-grid .box-container .flex a {
    padding: 3rem 4rem
  }
  .fa-html5{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-html5{
    font-size: 4rem;
  }
  .fa-css3{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-css3{
    font-size: 4rem;
  }
  .fa-js{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-js{
    font-size: 4rem;
  }
  
  .fa-bootstrap{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-bootstrap{
    font-size: 4rem;
  }
  .fa-react{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-react{
    font-size: 4rem;
  }
  .fa-file-code{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-file-code{
    font-size: 4rem; 
  }
  .fa-database{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-database{
    font-size: 4rem;
  }
  .fa-python{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-python{
    font-size: 4rem;
  }
  .fa-globe{
    font-size: 4rem;
  }
  .cheatsheet-box .flex a:hover .fa-globe{
    font-size: 4rem;
  }
  
  .home-grid .box-container .cheatsheet-box {
    margin-left: 5rem;
    width: 40rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 10rem;
width: 40rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: 10rem;
width: 40rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: 5rem;
width: 48rem;
height: 70rem;
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2.5rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 3rem 3rem;
  }
  .roadmap-box .fa-html5{
    font-size: 2.5rem;
    margin-left: 1.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-css3{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-js{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .fa-code{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 2.5rem;
    margin-left: 1.5rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 2.5rem;
    
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-react{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-node{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 2.5rem;
    margin-left: 1.5rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 2.5rem;
  }
  .roadmap-box .fa-java{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 2.5rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 2.5rem;
    margin-left: 5rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 2.5rem;
  }
  
  .footer-section {
 
    width:fit-content
  }
  .footer-section .container{
    width: 100%;
   
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
    margin-left: 10rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: -3rem !important;
    gap: 10rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: 0rem !important;
  }
  .copyright-text{
    margin-left: -70rem;
  }
  .footer-content{
    margin-left: 7rem;
    width: 45rem;
  }
  .form-container form {
    background-color: var(--white);
    border-radius: 4rem;
    padding: 2rem;
    width: 37rem;
    margin-left: 0rem;
  }
  .update .form-container form{
    width: 45rem !important;
  }
  .flex-btn {
    gap: 0;
    flex-flow: column;
  }

  .home-grid .box-container {
    grid-template-columns: 1fr;
  }

  .about .row .image img {
    height: 25rem;
  }
}
@media (max-width: 281px) {
  html {
    font-size: 40%;
  }
  .update .form-container img{
    margin-left: 10rem;
  }   
  .logo-header {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
}
  .sidebar.close{
    width: 9rem;
  }
  .sidebar.close ul{
    margin-top: 5rem;
    gap: 7rem;
    margin-left: 2rem;
  }
  .sidebar.close header .image{
    margin-top: 7rem;
  }
  .sidebar.open{
    font-size: 10rem;
    width: 100%;
  }
  .sidebar.open ul {
    margin-left: 22rem;
    margin-top: 10rem;
    gap: 4rem;
  }
  .sidebar.open header .image {
    margin-top: 7rem;
    margin-left: 27rem;
  }
  .sidebar.open .name{
    margin-top: 2rem;
    margin-left: -32rem;
  }
  .sidebar header .toggle-icon.open{
    right: -0.5rem;
    margin-top: 20rem !important;
  }
  .sidebar .toggle-icon.close {
    margin-top: 35rem;
  }
  .sidebar.open .logo-text{
    margin-top: 2rem;
    margin-left: 27rem;
  }
  .welcome-container{
    margin-top: 0rem !important;
    margin-left: 0rem !important;
    width: 100%;
  }
  .welcome-container h2{
    font-size: 1.5rem;
  }
  .welcome-container p span.welcome-typed-text{
    font-size: 1.5rem;
  }
  .profile-card:hover{
    border-radius: 10px;
    height: 40rem !important;
  }
  .title-apple{
    margin-left: 0rem !important;
  }
  #unlock .content{
    margin-left: -3rem !important;
  }
  .add-comment{
    margin-left: 4rem !important;
    margin-right: -1.5rem;
  }
  .main-title{
    font-size: 1.5rem !important;
    margin-left: 5rem;
    margin-top: 5rem;
  }
  .slide{
    width: 18rem !important;
    /* left: 13% !important; */
  }
.container-roadmap{
  margin-left: 5rem;
}
  
  .gita{
    margin-left: 2rem;
  }
  .event-sidebar-tab {
    margin-top: 40rem;
  }
  .home-grid .box-container .flex a {
    padding: 2rem 2rem
  }
  .fa-html5{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-html5{
    font-size: 3rem;
  }
  .fa-css3{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-css3{
    font-size: 3rem;
  }
  .fa-js{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-js{
    font-size: 3rem;
  }
  
  .fa-bootstrap{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-bootstrap{
    font-size: 3rem;
  }
  .fa-react{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-react{
    font-size: 3rem;
  }
  .fa-file-code{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-file-code{
    font-size: 3rem; 
  }
  .fa-database{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-database{
    font-size: 3rem;
  }
  .fa-python{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-python{
    font-size: 3rem;
  }
  .fa-globe{
    font-size: 3rem;
  }
  .cheatsheet-box .flex a:hover .fa-globe{
    font-size: 3rem;
  }
  
  .home-grid .box-container .cheatsheet-box {
    margin-left: 7rem;
    width: 30rem;
    height: auto;
  }
  .home-grid .box-container .playground-box {
    margin-left: 8rem;
width: 30rem;
  }
  .home-grid .box-container .notes-box {
    margin-left: 8rem;
width: 30rem;
  }
  .home-grid .box-container .roadmap-box {
    margin-left: 7rem;
width: 30rem;
height: 70rem;
  }
  .roadmap-box .flex a{
    width: 90%;
  }
  .home-grid .box-container .cheatsheet-box .flex{
    gap: 2rem;
    padding-left: 3rem;
  }
  .home-grid .box-container .roadmap-box .flex a {
    margin-left: 2rem;
    padding: 1rem 1rem;
  }
  .roadmap-box .fa-html5{
    font-size: 1.85rem;
    margin-left: 0.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
  .roadmap-box .flex a:hover .fa-html5{
    font-size: 1.85rem;
  }
  .roadmap-box .fa-css3{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a:hover .fa-css3{
    font-size: 1.85rem;
  }
  .roadmap-box .fa-js{
    font-size: 1.85rem;
    margin-left: 2rem ;
  }
  .roadmap-box .flex a:hover .fa-js{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .fa-code{
    font-size: 1.85rem;
    margin-left: 2rem !important;
  }
  .roadmap-box .flex a:hover .fa-code{
    font-size: 1.85rem;
  }
  .roadmap-box .fa-leaf{
    margin-top: 1rem;
    font-size: 1.85rem;
    margin-left: 0.5rem;
  }
  .roadmap-box .flex a:hover .fa-leaf{
    font-size: 1.85rem;
  }
  .roadmap-box .express{
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .roadmap-box .fa-circle{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a:hover .fa-circle{
    font-size: 1.85rem;
  }
  .roadmap-box .fa-react{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a:hover .fa-react{
    font-size: 1.85rem;
  }
  .roadmap-box .fa-node{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a:hover .fa-node{
    font-size: 1.85rem;
  }
  .roadmap-box .flex a .fa-python{
    font-size: 1.85rem;
    margin-left: 0.5rem;
  }
  .roadmap-box .flex a:hover .fa-python{
    font-size: 1.85rem;
  }
  .roadmap-box .fa-java{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a:hover .fa-java{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a .fa-gem{
    font-size: 1.85rem;
    margin-left: 2rem;
  }
  .roadmap-box .flex a:hover .fa-gem{
    font-size: 1.85rem;
  }
  .buttongpt{
    padding-bottom: 0.2rem;
    margin-left: -45rem;
    width: 18rem;
    margin-top: -1rem;
  }
  .welcome-text{
    font-size: 1.5rem;
    letter-spacing: 0.8rem;
  }
  .header .flex .icons{
    /* margin-left: -37rem; */
  }
  .slide{
    width: 2rem;
    left: 32%;
    transform: translateX(-50%);
  }
  .slide.active {
    z-index: 4;
    position: absolute;
    top: 8%;
    left: 30%;
    transform: translate(-50%, -50%);
  }
  .slider {
    margin: 8rem 0;
    height: 20rem;
    display: grid; /* Use CSS Grid for layout */
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr)); /* Divide the slides equally */
    gap: 2rem !important; /* Adjust the gap between slides */
    perspective: 100rem;
    position: relative;
    width: 100vw;
  }

  .slider {
    margin: 4rem 0; /* Decrease the top margin */
  }
  .courses .heading{
    margin-left: -13rem;
  }
  .courses ul#tabs{
    margin-left: -15rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 35rem;
    gap: 2rem;
  }
  .courses ul#tabs li{
    width: 8rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .courses .box-container{
    margin-left: -13rem;
    gap: 5rem;
  }
  .user-list .userlist-searchbar{
    margin-left: 2rem;
    width: 25rem;
   }
  .slide {
    margin-left: 0; /* Remove the negative margin */
    width: 100%; /* Make slides take up full width */
    border-radius: 0; /* Remove border-radius */
    padding: 1rem; /* Decrease padding */
    overflow-x: hidden;
  }

  .slide-container {
    padding: 1rem; /* Decrease padding */
  }

  /* Adjust other styles as needed for better readability on small screens */
  .main-title {
    font-size: 3rem; /* Decrease font size */
  }

  .playlist{
    margin-left: 5rem;
  }
  .playlist-details{
    width: auto;
  }
  .playlist-videos{
    width: auto;
  }
  .form-container{
    width: 60rem;
  }
  .form-container form{
    width: 27rem;
    margin-left: -17rem !important;
  }
  .contact .row form{
    margin-top: 15rem;
    margin-left: 15rem;
    margin-bottom: 5rem;
    width: 50rem;
  }
  /* .courses{
    margin-left: 20rem;
  } */
  .about{
    margin-top: 10rem;
    /* margin-left: 6rem; */
  }
  .about .box-container{
    /* margin-left: 40%; */
    width: 90%;
  }
  .card-social ul {
    margin-left: -3rem;
  }
  .reviews{
    /* margin-left: 10rem; */
  }
  .flex-btn {
    gap: 0;
    flex-flow: column;
  }

  .home-grid .box-container {
    grid-template-columns: 1fr;
  }
  .footer-section {

    width:fit-content
  }
  .footer-section .container{
    width: 100%;
  
  }
  .footer-cta{
    margin-left: 7rem;
  }
  .footer-cta .row{
    gap: 0.1rem;
  }
  .footer-cta .row .mb-30{
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  .cta-text{
    padding-left: 1rem;
  }
  .footer-main{
    margin-left: -9rem !important;
    gap: 10rem;
    flex-direction: column;
  }
  .footer-widget{
    margin-left: 0rem !important;
  }
  .copyright-area{
    margin-left: -20rem;
    font-size: 0.5rem !important;
    width: 100%;
  }
  .footer-content{
    margin-left: 7rem;
    width: 30rem;
  }
}







.main-wrapper {
  font-size: var(--main-wrapper-font-size);
    width: 0;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 0rem;
}

/* Signboard Wrapper */
.signboard-wrapper {
  width: var(--signboard-wrapper-width);
  height: var(--signboard-wrapper-height);
  margin: var(--signboard-margin);
  position: relative;
  margin-top: 40rem;
  flex-shrink: 0;
  transform-origin: var(--signboard-wrapper-transform-origin);
  animation:
    var(--signboard-wrapper-init-time) init forwards,
    var(--signboard-wrapper-init-sign-move-time) init-sign-move ease-out var(--signboard-wrapper-init-time),
    var(--signboard-wrapper-sign-move-time) sign-move var(--signboard-wrapper-sign-move-delay-time) infinite;
}

.animate-bounce {
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-20px);
  }
}
/* Signboard */
.signboard {
  color: var(--signboard-color);
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  background-color: var(--signboard-background-color);
  width: 100%;
  height: var(--signboard-height);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  border-radius: var(--signboard-wrapper-border-radius);
  text-shadow: 0 -0.015em var(--signboard-text-shadow-color);
  box-shadow: var(--signboard-box-shadow);
}

/* String */
.string {
  width: var(--string-width);
  height: var(--string-height);
  border: solid var(--string-border-width) var(--string-border-color);
  border-bottom: none;
  border-right: none;
  position: absolute;
  left: 50%;
  transform-origin: top left;
  transform: rotate(45deg);
}

/* Pin */
.pin {
  width: var(--pin-width);
  height: var(--pin-height);
  position: absolute;
  border-radius: 50%;
}

/* Pin 1 */
.pin.pin1 {
  background-color: var(--pin-pin1-background-color);
  top: 0;
  left: calc(50% - (var(--pin-width) / 2));
}

/* Pin 2 and Pin 3 */
.pin.pin2,
.pin.pin3 {
  background-color: var(--pin-pin2-3-background-color);
  top: var(--pin-pin2-3-y);
}

/* Pin 2 */
.pin.pin2 {
  left: var(--pin-pin2-3-x);
}

/* Pin 3 */
.pin.pin3 {
  right: var(--pin-pin2-3-x);
}

/* Keyframes */
@keyframes init {
  0% { transform: scale(0); }
  40% { transform: scale(1.1); }
  60% { transform: scale(0.9); }
  80% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes init-sign-move {
  100% { transform: rotate(var(--signboard-wrapper-rotation-degree)); }
}

@keyframes sign-move {
  0% { transform: rotate(var(--signboard-wrapper-rotation-degree)); }
  50% { transform: rotate(calc(-1 * var(--signboard-wrapper-rotation-degree))); }
  100% { transform: rotate(var(--signboard-wrapper-rotation-degree)); }
}
.comingsoongif {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow:hidden;
  height:60rem;
  position: sticky;

 
}

.comingsoongifimg {
 width: 100%;
 
 
}
.comingsoonheading{
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 8rem;
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient( 191deg, rgb(255 0 220) 0%, rgb(255 120 0) 100% );
  color: #e5895f;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
z-index: 2;
}



.youtube-preview {
  overflow: hidden;
  padding-bottom: 40.25%;
  position: relative;
  /* min-height:100vh; */
}

.youtube-preview iframe {
  left: 4.2rem;
  top: 0;
  max-height: 60rem;
  width: 100%;
  height:100%;
 /* max-width: 117rem; */
  position: absolute;
}

